.main-contents {
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 130px;

  padding: 120px 0;
}

.overview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  gap: 20px;
}

#main-title {
  /* font-family: "NotoSansKR-Medium"; */
  font-size: 70px;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 0;
  /* margin: 16px 8px; */
  width: 100%;
  height: auto;

  display: flex;
  /* text-rendering: optimizeLegibility; */
  /* -webkit-font-smoothing: inherit; */

  @media screen and (max-width: 1000px) {
    font-size: 48px;
    margin: 8px 0px;
  }
}

#scroller-container {
  height: 80px;
  margin-bottom: -6px;
  overflow: hidden;
  position: relative;
  padding: 0 10px;
}

#scroller-container .scroller_item {
  /* width: 15vw; */
  display: flex;
  align-items: center;
  height: 100%;
  /* padding-bottom: 6px; */
  font-size: 54pt;
  /* font-size: 72px; */
  /* font-size: 4.5rem; */
  font-family: Pretendard;
  animation: scroll 18s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;

  /* @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) { */
  /* @media (max-width: 750pt) { */
  /* @media screen and (max-width: 750pt) { */
  /* @media screen and (max-width: 1000px) {
    background-color: yellow;
    padding-bottom: 4px;
    font-size: 50px;
    font-size: 37.5pt;
    font-size: 37.5pt;
    animation: scroll 18s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
  } */
}

@media screen and (max-width: 1000px) {
  #scroller-container .scroller_item {
    /* height: 80px; */
    padding-bottom: 4px;
    font-size: 50px;
  }
}

@keyframes scroll {
  /*교육*/
  0% {
    transform: translateY(0px);
  }

  3.7037037037037037% {
    transform: translateY(0px);
  }
  /*학습*/
  11.11111111111111% {
    transform: translateY(-100%);
  }

  14.814814814814815% {
    transform: translateY(-100%);
  }
  /*몰입*/
  22.22222222222222% {
    transform: translateY(-200%);
  }

  25.925925925925927% {
    transform: translateY(-200%);
  }
  /*성장*/
  33.333333333333336% {
    transform: translateY(-300%);
  }

  37.03703703703704% {
    transform: translateY(-300%);
  }
  /*혁신*/
  44.44444444444444% {
    transform: translateY(-400%);
  }
  48.148148148148145% {
    transform: translateY(-400%);
  }
  /*경쟁*/
  55.55555555555556% {
    transform: translateY(-500%);
  }
  59.25925925925926% {
    transform: translateY(-500%);
  }
  /*배움*/
  66.66666666666667% {
    transform: translateY(-600%);
  }
  70.37037037037037% {
    transform: translateY(-600%);
  }
  /*혁신*/
  77.77777777777777% {
    transform: translateY(-700%);
  }
  81.48148148148148% {
    transform: translateY(-700%);
  }
  /*성장*/
  88.88888888888889% {
    transform: translateY(-800%);
  }
  92.5925925925926% {
    transform: translateY(-800%);
  }
  /*교육*/
  100% {
    transform: translateY(-900%);
  }
}

@keyframes scroll-mobile {
  0% {
    transform: translateY(0px);
  }
  3.7037037037037037% {
    transform: translateY(0px);
  }
  11.11111111111111% {
    transform: translateY(-52px);
  }
  14.814814814814815% {
    transform: translateY(-52px);
  }
  22.22222222222222% {
    transform: translateY(-104px);
  }
  25.925925925925927% {
    transform: translateY(-104px);
  }
  33.333333333333336% {
    transform: translateY(-156px);
  }
  37.03703703703704% {
    transform: translateY(-156px);
  }
  44.44444444444444% {
    transform: translateY(-208px);
  }
  48.148148148148145% {
    transform: translateY(-208px);
  }
  55.55555555555556% {
    transform: translateY(-260px);
  }
  59.25925925925926% {
    transform: translateY(-260px);
  }
  66.66666666666667% {
    transform: translateY(-312px);
  }
  70.37037037037037% {
    transform: translateY(-312px);
  }
  77.77777777777777% {
    transform: translateY(-364px);
  }
  81.48148148148148% {
    transform: translateY(-364px);
  }
  88.88888888888889% {
    transform: translateY(-416px);
  }
  92.5925925925926% {
    transform: translateY(-416px);
  }
  100% {
    transform: translateY(-468px);
  }
}

#scroller-container .scroller_item:nth-child(1) {
  color: #e65f48;
}

#scroller-container .scroller_item:nth-child(2) {
  color: #349ab5;
}

#scroller-container .scroller_item:nth-child(3) {
  color: #76bcad;
}

#scroller-container .scroller_item:nth-child(4) {
  color: #84719f;
}

#scroller-container .scroller_item:nth-child(5) {
  color: #d47787;
}

#scroller-container .scroller_item:nth-child(6) {
  color: #f09551;
}

#scroller-container .scroller_item:nth-child(7) {
  color: #349ab5;
}
#scroller-container .scroller_item:nth-child(8) {
  color: #d47787;
}
#scroller-container .scroller_item:nth-child(9) {
  color: #84719f;
}
#scroller-container .scroller_item:nth-child(10) {
  color: #e65f48;
}

.sub-title {
  font-family: "NotoSansKR-Medium";
  font-size: 28px;
  line-height: 1;
  font-weight: bold;
  white-space: nowrap;
  /* padding: 0.8rem 1rem 0.9rem 1rem; */
  color: #353535;

  /* background-color: #eee; */
  border-radius: 0.2rem;

  @media screen and (max-width: 767px) {
    font-size: 20px;
    padding: 0;
    background-color: white;
  }
}

.text-logo {
  color: #c60f0f;
  font-family: "Roboto-Black";
}

.button {
  background: linear-gradient(97.7deg, #c60f0f 1.23%, #630808 134.48%);
  border-radius: 8px;
  border: none;
  min-width: 150px;
  padding: 0 44px;
  color: white;
  font-family: "NotoSansKR-Medium";
  font-size: 22px;
  box-shadow: 2px 2px 4px #979797;
  margin-left: 5px;

  @media screen and (max-width: 767px) {
    height: 40px;
    padding: 0 40px;
    font-size: 18px;
  }
}

.button:hover {
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
}

.button:active {
  transform: scale(0.9);
  transition: all 0.2s ease-out;
  box-shadow: 1px 1px 4px black;
}

/** 서비스 */
.services {
  height: 100%;
  width: 100%;
  /* border-radius: 4px; */
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 767px) {
    display: flex;
    height: 200px;
    width: 100%;
  }
}

/** 컨텐츠 */
.contents-list {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 75%;
  margin: 0;
  padding: 0 80px;

  @media screen and (max-width: 767px) {
    justify-content: center;
    width: 65%;
    padding: 0;
  }
}

.contents-item {
  width: 300px;
  height: 400px;
  /* background-image: linear-gradient(315deg, #ee9617 0%, #fe5858 74%); */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: black;
  /* border-radius: 10px; */
  /* border: 1px solid black; */
  /* background-color : #ee9617;
  background-image : linear-gradient(315deg, #ee9617 0%, #fe5858 60%); */

  /* filter: drop-shadow(5px 5px 5px #979797); */

  @media screen and (max-width: 767px) {
    width: 70px;
    height: 100px;
    padding: 0;
  }
}
.contents-item span {
  font-family: "NotoSansKR-Medium";
  font-size: 15px;
}

.contents-item > #contents-img {
  margin: 0 auto;
  width: 300px;
  height: 150px;
  margin-top: 20px;
  margin-bottom: 40px;
  object-fit: scale-down;
  @media (max-width: 2100px) {
    width: 250px;
  }
  @media (max-width: 2000px) {
    width: 230px;
  }
  @media (max-width: 1800px) {
    width: 150px;
  }
}

.contents-description {
  height: 50%;
  /* border:1px solid black; */
  font-size: 18px;
  margin-bottom: 30px;
  text-align: center;
  font-family: "NotoSansKR-Medium";

  @media (max-width: 1900px) {
    font-size: 15px;
  }
}

@media screen and (min-width: 767px) {
  /* .contents-item :hover {
    transform: scale(1.2);
    transition: all 0.3s ease-in-out;
  } */
}

.mobile-banner {
  width: 100%;
  margin-bottom: 10vw;
}

.mobile-banner-content {
  display: flex;
  flex-direction: column;
  border: 0;
  background-color: #eee;
  /* background-image: linear-gradient(315deg, #ee9617 0%, #fe5858 74%); */
  width: 70%;
  box-shadow: 2px 5px 5px rgb(211, 211, 211), -2px 5px 5px rgb(211, 211, 211);
  height: 380px;
  /* justify-content:center; */
  /* text-align: left; */
  margin: 0 auto;
  padding: 20px;
  border-radius: 15px;
}
.mobile-banner-content > div > p {
  font-family: "NotoSansKR-Medium";
  color: black;
  width: 100%;
}

.mobile-banner-content > span {
  font-family: "NotoSansKR-Medium";
  font-weight: bold;
  font-size: 30px;
  text-align: center;
}
