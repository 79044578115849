.Program {
  /* border: 2px solid #2382b9; */
  width: 95%;
  height: 130px;
  border-radius: 20px;
  margin: auto;
  margin-bottom: 20px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.Program:active {
  transform: translateY(10px);
  box-shadow: none;
}

.Program:hover {
  background-color: #f9f9f9;
}

.Program .icon-container {
  background-color: #b43f3f;
  float: left;
  width: 80px;
  height: 80px;
  margin-left: 5%;
  border-radius: 50%;
  display: flex;
  align-items: center;
}

.Program .title {
  height: auto;
  margin-left: 3%;
  text-align: left;
}

.Program .title .name {
  font-size: 1.3rem;
  font-weight: 500;
  padding-bottom: 10px;
  padding-left: 5px;
  box-sizing: border-box;
}

.Program .title .tag {
  border-radius: 10%;
  background-color: #b43f3f;
  padding: 3px 12px 3px 12px;
  margin: 5px;
  font-size: 0.9rem;
}

.Program .days {
  color: #878787;
  font-weight: 600;
}

.Program .daysleft {
  color: #b2b2b2;
  font-size: 0.9rem;
}

.RecentProgram {
  /* border: 2px solid #2382b9; */
  width: 80%;
  height: 80px;
  margin: auto;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  align-items: center;
}

.RecentProgram .date {
  height: auto;
  width: 20%;
}

.RecentProgram .date-user {
  height: auto;
  width: 50%;
  text-align: left;
  padding-left: 10px;
  box-sizing: border-box;
}
