.paper {
  width: 792px;
  height: 1122.5px;
  background-color: #fff;
}

.Page {
  font-family: "Pretendard";
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
}

.PageHeader {
  box-sizing: border-box;
  position: absolute;
  top: 45px;
  height: 25px;
  width: 100%;
  background-color: #f5c242;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-left: 26px;
}

/* 1번째 페이지 */

.LineTop {
  width: 100%;
  border-top: 1px solid #a6a6a6;
  margin-top: 83px;
  margin-bottom: 10px;
  padding: 0;
}
.LineBottom {
  width: 100%;
  border-bottom: 1px solid #a6a6a6;
  margin-top: 10px;
  padding: 0;
}

.FirstPageTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 280px;
  padding: 0;

  background-color: #dce3f2;

  line-height: 100px;
}

.TitleText {
  font-size: 56px;
  font-weight: bold;
  text-align: center;
}

.FirstPageImage {
  height: 430px;
  margin-top: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.FirstPageImage img {
  width: 60%;
  height: 100%;
}

.FirstPageUser {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;

  margin-top: 40px;
}

.FirstPageUser table {
  border-collapse: collapse;
}

.FirstPageUser th {
  font-weight: 500;
}

.FirstPageUser th,
.FirstPageUser td {
  border-bottom: 1px solid #a6a6a6;
  padding: 10px;
  text-align: center;
  border-left: none;
  border-right: none;
  width: 130px;
}
.FirstPageUser tr:first-child {
  border-top: 1px solid #a6a6a6;
}

.FirstPageUser tr th:last-child,
.FirstPageUser tr td:last-child {
  border-left: 1px solid #a6a6a6;
}

.PageFooter {
  box-sizing: border-box;
  width: 100%;
  height: 65px;
  background-color: #f2f2f2;
  position: absolute;
  bottom: 1px;

  margin-top: 70px;
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
}

.PageFooter p {
  color: #bfbfbf;
  font-size: 12px;
  font-style: italic;
}

/* 2번째 페이지 */

.PageTitle {
  margin-top: 100px;

  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  height: auto;

  padding: 0;

  box-sizing: border-box;
}

.TitlePart {
  box-sizing: border-box;

  color: white;
  font-weight: 500;
  font-size: 18px;

  background-color: #081f5c;
  height: 50px;
  width: 130px;
  border-radius: 0 50px 50px 0;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 24px;
}

.TitlePartName {
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 0 20px;
  letter-spacing: 1px;
}

.SecondContentsFirst {
  margin-top: 20px;
  width: 100%;
  padding: 0;
}

.SecondContentsFirst p {
  padding: 0px 20px;
  font-size: 15px;
  line-height: 24px;
  margin: 0;
}

.SecondContentsSecond {
  background-color: #f2f2f2;
  margin: 16px 20px 0px 20px;
  border-radius: 30px;
  padding: 0;
}

.SecondContentsSecond p {
  margin: 0;
  padding: 10px 20px;
  line-height: 20px;
  font-size: 14px;
}

.SecondContentsThird {
  margin-top: 18px;
  width: 100%;
  padding: 0;
  display: inline;
}

.SecondThirdContent {
  margin: 0;
  padding: 0 20px;
  line-height: 22px;
  font-size: 14px;
}

.SecondThirdContent span {
  font-weight: bold;
}

.SecondPageImage {
  margin-top: 20px;

  height: 250px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.SecondPageImage img {
  width: 30%;
  height: 100%;
}

.SecondContentsFourthTitle {
  margin-top: 24px;
  font-size: 18px;
  width: 100%;
  padding: 0 40px;
}

.SecondContentsFourthContent {
  background-color: #f2f2f2;
  margin: 5px 20px 0px 20px;
  border-radius: 30px;
  padding: 0;
}

.SecondContentsFourthContent p {
  margin: 0;
  padding: 10px 20px;
  line-height: 21px;
  font-size: 14px;
}

/* 3번째 페이지 */

.ThirdContentsFirst {
  margin-top: 20px;
  width: 100%;
  padding: 0;
}

.ThirdContentsFirst > p {
  padding: 0px 20px;
  font-size: 15px;
  line-height: 24px;
  margin: 0;
}

.ThirdContentsSecond {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 20px;
}

.ThirdContentsSecond div {
  width: 60%;
}

.ThirdContentsThirdTitle {
  margin-top: 40px;
  display: inline-block;
  margin-left: 30px;
}

.ThirdContentsThirdTitle div {
  display: inline-block;

  padding: 12px 16px;
  background-color: #081f5c;
  color: white;
  border-radius: 20px;
  width: auto;

  font-size: 17px;
  font-weight: bold;
}

.ThirdContentsThirdTitle {
  margin-top: 40px;
  display: inline-block;
  margin-left: 30px;
}

.ThirdContentsThirdTitle div {
  display: inline-block;

  padding: 12px 16px;
  background-color: #081f5c;
  color: white;
  border-radius: 20px;
  width: auto;

  font-size: 17px;
  font-weight: bold;
}

.ThirdContentsThirdContents {
  margin: 10px 20px 0 20px;
  padding: 14px 14px;

  border: 1px solid #081f5c;
  border-radius: 12px;
}

.ThirdContentsThirdContents p {
  padding: 0;
  margin: 0;

  line-height: 24px;
  white-space: pre-wrap;
}

/* 4번째 페이지 */

.FourthTable {
  margin-top: 20px;
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
}

.FourthTable table {
  width: 94%;
  border-collapse: collapse;
}

.FourthTable th {
  padding: 10px 14px;
  border-left: 1px solid #a6a6a6;
  font-weight: 600;
  background-color: #081f5c;
  color: white;
}

.FourthTable td {
  padding: 10px 14px;
  border-left: 1px solid #a6a6a6;
  font-weight: 500;
  border-bottom: 1px solid #a6a6a6;
  text-align: center; /* 숫자들을 가운데 정렬 */
}

.FourthTable td:last-child,
.FourthTable th:last-child {
  border-right: 1px solid #a6a6a6;
}

.FourthContentsFirst {
  margin-top: 20px;
  width: 100%;
  padding: 0;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.FourthContentsFirst p {
  margin: 0;
  padding: 0 24px;
  line-height: 24px;
  font-size: 16px;
}

.FourthContentsFirstTitle {
  margin-top: 50px;
  display: inline-block;
  margin-left: 20px;
}

.FourthContentsFirstTitle div {
  display: inline-block;
  padding: 16px 26px 16px 20px;
  color: black;
  width: auto;
  line-height: 26px;
  font-size: 16px;
  font-weight: 500;
}

.FourthContentsFirstContents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 20px 0 20px;
  padding: 16px 14px;
  gap: 10px;

  border: 1px solid #a6a6a6;
  border-radius: 12px;
}

.FourthContentsFirstContents p {
  padding: 0;
  margin: 0;
}

.FourthContentsFirstContents p:first-child {
  font-weight: 600;
  font-size: 16px;
}

.FourthContentsFirstContents p:last-child {
  line-height: 23px;
  white-space: pre-wrap;
}

.FourthContentsSecondTitle {
  margin-top: 14px;
  display: inline-block;
  margin-right: 20px;

  text-align: right;
}

.FourthContentsSecondTitle div {
  display: inline-block;
  padding: 16px 20px 16px 26px;
  color: black;
  width: auto;
  line-height: 26px;
  font-size: 16px;
  font-weight: 500;
}

/* 5번째, 8번째 페이지 */

.UserTypeInfo {
  margin-top: 30px;
  margin-left: 20px;
  display: flex;
  box-sizing: border-box;
  justify-content: space-evenly;
  align-items: center;
  gap: 60px;
}

.UserTypeImage {
  width: 320px;
  height: 340px;
}

.UserTypeImage div {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #e9e9e9;
  border: 1px solid #a9a9a9;
}

.UserTypeName {
  width: 280px;
}

.UserTypeName div {
  width: 100%;
  height: 120px;
  background-color: #081f5c;
  border-radius: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
}

.ProsAndConsTitle {
  margin-top: 100px;
  display: inline-block;
  margin-left: 30px;
}

.ProsAndConsTitle div {
  display: inline-block;

  padding: 14px 18px;
  background-color: #dce3f2;
  border-radius: 8px;
  width: auto;

  font-size: 18px;
  font-weight: bold;
}

.ProsAndConsContent {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.ProsAndConsContent table {
  width: 94%;
  border-collapse: collapse;
  margin: 0 auto; /* 표를 가운데 정렬 */
  table-layout: fixed; /* 고정된 테이블 레이아웃 */
}

.ProsAndConsContent th,
.ProsAndConsContent td {
  width: 50%; /* 각 셀의 너비를 50%로 설정 */
}

.ProsAndConsContent th {
  padding: 14px 14px;
  border-left: 1px solid #a6a6a6;

  background-color: #081f5c;
  color: white;
  font-weight: 600;
  font-size: 18px;
}

.ProsAndConsContent td {
  padding: 10px 16px;
  border-left: 1px solid #a6a6a6;
  border-bottom: 1px solid #a6a6a6;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;

  white-space: pre-wrap;
}

.ProsAndConsContent td:last-child,
.ProsAndConsContent th:last-child {
  border-right: 1px solid #a6a6a6;
}

.ProsAndConsContent tbody td {
  text-align: left;
  padding: 10px;
}

/* 6번째 페이지 */

.UserStressTitle {
  margin-top: 20px;
  display: inline-block;
}

.UserStressTitle p {
  background-color: #f7e6d8;
  display: inline-block;
  border-radius: 8px;
  margin: 0 30px;
  padding: 14px 18px;

  font-size: 18px;
  font-weight: 600;
}

.UserStressContent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14px;

  display: flex;
  flex-direction: column;
  gap: 14px;
}

.UserStressContent table {
  width: 94%;
  border-collapse: collapse;
}

.UserStressContent th {
  padding: 14px 14px;
  border-left: 1px solid #a6a6a6;

  background-color: #4a4a4a;
  color: white;
  font-weight: 600;
  font-size: 18px;
}

.UserStressContent td {
  padding: 10px 16px;
  border-left: 1px solid black;
  border-bottom: 1px solid black;

  font-weight: 500;
  font-size: 16px;
  line-height: 30px;

  white-space: pre-wrap;
}

.UserStressContent td:last-child,
.UserStressContent th:last-child {
  border-right: 1px solid #a6a6a6;
}

.UserStressContent td:last-child {
  text-align: left;
}

.StressManagementTitle {
  margin-top: 30px;
  display: inline-block;
}

.StressManagementTitle p {
  background-color: #dce3f2;
  display: inline-block;
  border-radius: 8px;
  margin: 0 30px;
  padding: 14px 18px;

  font-size: 18px;
  font-weight: 600;
}

.StressManagementContent {
  width: 100%;
  margin-top: 14px;
  box-sizing: border-box;
}

.StressManagementContent p {
  box-sizing: border-box;
  margin: 0 30px;
  background-color: #f2f2f2;
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  padding: 12px 14px;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;

  white-space: pre-wrap;
}

/* 7번째, 10번째 페이지 */

.UserMotivationTitle {
  margin-top: 20px;
  display: inline-block;
}

.UserMotivationTitle p {
  background-color: #f7e6d8;
  display: inline-block;
  border-radius: 8px;
  margin: 0 30px;
  padding: 14px 18px;

  font-size: 18px;
  font-weight: 600;
}

.UserMotivationContents {
  margin: 20px 30px 0 30px;
  padding: 30px 20px;

  display: flex;
  flex-direction: column;

  background-color: #f2f2f2;
  border: 1px solid #bfbfbf;
  border-radius: 8px;

  gap: 30px;
}

.UserMotivationContentsBox {
  display: flex;
  flex-direction: column;
}

.UserMotivationContentsTitle {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.UserMotivationContentsContent {
  font-size: 17px;
  font-weight: 500;
  line-height: 30px;
  padding-left: 20px;
  margin-top: 18px;

  white-space: pre-wrap;
}
