@font-face {
  font-family: 'NotoSans-ExtraBold';
  src: url('../../../assets/fonts/Noto_Sans/NotoSans-ExtraBold.ttf') format('woff');
  /* font-weight: normal;
  font-style: normal; */
}

@font-face {
  font-family: 'SUIT-ExtraBold';
  src: url('../../../assets/fonts/SUIT/SUIT-ExtraBold.ttf') format('woff');
  /* font-weight: normal;
  font-style: normal; */
}

.contentBox{
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.title{
  display: flex ;
  justify-content: center;
}

.inputBox{
  display: block ;
  text-align: center;
  /* border: 1px solid black; */
}
.containerBox{
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border:1px solid red; */
}

.mobileContainerBox{
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border:1px solid green; */
  height: 450px;
  margin: 0 auto;
}

.gameBox{
  /* border: 3px solid pink; */
  background-color: none;
  /* height:700px; */
}

.mobileGameBox{
    /* border: 3px solid pink; */
    background-color: none;
    width: 300px;
    height: 500px;
}

.resultBox{
  justify-content:center;
  display:flex;
  width: 300px;
  margin: 0 auto;
  /* border: 1px solid black; */
}

.optionInputBox{
  /* position: absolute; */
  /* border: 4px solid pink; */
  width: 300px;
  margin: 0 auto;
  height: 330px;
  padding: 10px;
  margin-bottom: 20px;
}

.MobileOptionInputBox{
  /* border: 3px solid black; */
  width: 280px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: -145px auto 0 auto;
}

.MobileInputCss{
  text-align: center;
  width:90px;
  height:23px;
  border-radius:5px;
  border: 1px solid rgb(222, 219, 219);
  vertical-align:middle;
  
}


.boxStyle{
  width:50px;
  height:5px;
  margin:0 auto -10px auto;
  background-color:#60A7FF;
  border-radius:15px;
}

.boxStyle2{
  width:50px;
  height:5px;
  margin:-20px auto;
  background-color:#60A7FF;
  border-radius:15px;
}

.inputCss{
  text-align: center;
  width:150px;
  height:25px;
  border-radius:5px;
  border: 1px solid rgb(222, 219, 219);
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.labelFont{
  font-family: 'NotoSans-ExtraBold'
}

.h1Font{
  font-family: 'SUIT-ExtraBold';
}
.buttonBox {
  display: flex;
  margin: 0px auto;
  justify-content: center;
  border: 2px solid rgb(222, 219, 219);
  background-color: white;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  align-items: center;
  width: 180px;
  height: 40px;
}

.mobileButtonBox{
  display: flex;
  margin: 0px auto;
  justify-content: center;
  border: 2px solid rgb(222, 219, 219);
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  align-items: center;
  width: 147px;
  height: 30px;
}

.button {
  width: 30px;
  height: 30px;
  /* position: relative; */
  /* left: 50px; */
}

.mobileButton{
  width: 25px;
  height: 25px;
}

.numberOfOptionsH2{
  /* position: relative; */
  /* left: 50px; */
  margin: 0;
  padding: 0;
}

.startOrReset{
  width: 80px;
  height: 50px;
  border: 2px solid rgb(222, 219, 219);
  background-color: white;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 10px;
}
.mobileStartOrReset{
  width: 73px;
  height: 35px;
  border: 2px solid rgb(222, 219, 219);
  background-color: white;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 5px;
}

.resultInput{
  border-radius:5px;
  border: 1px solid rgb(222, 219, 219);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.boxStyle{
  width:50px;
  height:5px;
  background-color:#60A7FF;
  border-radius:15px;
}
.mobileBody {
  height: calc(var(--vh, 1vh) * 103);
  padding-top: 90px;
  box-sizing: border-box;
  background-color: #f5f5f5;
}

@media (max-width: 1000px) and (orientation: landscape) {
  .mobileBody {
    height: 100vw;
  }
}

@media (max-height: 650px) {
  .mobileBody {
    height: 700px;
  }
}