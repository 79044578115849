/* Margin */
.m-1 {
  margin: 1px;
}
.m-2 {
  margin: 2px;
}
.m-3 {
  margin: 3px;
}
.m-4 {
  margin: 4px;
}
.m-5 {
  margin: 5px;
}
.m-6 {
  margin: 6px;
}
.m-7 {
  margin: 7px;
}
.m-8 {
  margin: 8px;
}
.m-9 {
  margin: 9px;
}
.m-10 {
  margin: 10px;
}
.m-11 {
  margin: 11px;
}
.m-12 {
  margin: 12px;
}
.m-13 {
  margin: 13px;
}
.m-14 {
  margin: 14px;
}
.m-15 {
  margin: 15px;
}
.m-16 {
  margin: 16px;
}
.m-17 {
  margin: 17px;
}
.m-18 {
  margin: 18px;
}
.m-19 {
  margin: 19px;
}
.m-20 {
  margin: 20px;
}
.m-21 {
  margin: 21px;
}
.m-22 {
  margin: 22px;
}
.m-23 {
  margin: 23px;
}
.m-24 {
  margin: 24px;
}
.m-25 {
  margin: 25px;
}
.m-26 {
  margin: 26px;
}
.m-27 {
  margin: 27px;
}
.m-28 {
  margin: 28px;
}
.m-29 {
  margin: 29px;
}
.m-30 {
  margin: 30px;
}
.m-31 {
  margin: 31px;
}
.m-32 {
  margin: 32px;
}
.m-33 {
  margin: 33px;
}
.m-34 {
  margin: 34px;
}
.m-35 {
  margin: 35px;
}
.m-36 {
  margin: 36px;
}
.m-37 {
  margin: 37px;
}
.m-38 {
  margin: 38px;
}
.m-39 {
  margin: 39px;
}
.m-40 {
  margin: 40px;
}
.m-41 {
  margin: 41px;
}
.m-42 {
  margin: 42px;
}
.m-43 {
  margin: 43px;
}
.m-44 {
  margin: 44px;
}
.m-45 {
  margin: 45px;
}
.m-46 {
  margin: 46px;
}
.m-47 {
  margin: 47px;
}
.m-48 {
  margin: 48px;
}
.m-49 {
  margin: 49px;
}
.m-50 {
  margin: 50px;
}

/* Margin Left And Right*/
.mx-1 {
  margin: 0px 1px;
}
.mx-2 {
  margin: 0px 2px;
}
.mx-3 {
  margin: 0px 3px;
}
.mx-4 {
  margin: 0px 4px;
}
.mx-5 {
  margin: 0px 5px;
}
.mx-6 {
  margin: 0px 6px;
}
.mx-7 {
  margin: 0px 7px;
}
.mx-8 {
  margin: 0px 8px;
}
.mx-9 {
  margin: 0px 9px;
}
.mx-10 {
  margin: 0px 10px;
}
.mx-11 {
  margin: 0px 11px;
}
.mx-12 {
  margin: 0px 12px;
}
.mx-13 {
  margin: 0px 13px;
}
.mx-14 {
  margin: 0px 14px;
}
.mx-15 {
  margin: 0px 15px;
}
.mx-16 {
  margin: 0px 16px;
}
.mx-17 {
  margin: 0px 17px;
}
.mx-18 {
  margin: 0px 18px;
}
.mx-19 {
  margin: 0px 19px;
}
.mx-20 {
  margin: 0px 20px;
}
.mx-21 {
  margin: 0px 21px;
}
.mx-22 {
  margin: 0px 22px;
}
.mx-23 {
  margin: 0px 23px;
}
.mx-24 {
  margin: 0px 24px;
}
.mx-25 {
  margin: 0px 25px;
}
.mx-26 {
  margin: 0px 26px;
}
.mx-27 {
  margin: 0px 27px;
}
.mx-28 {
  margin: 0px 28px;
}
.mx-29 {
  margin: 0px 29px;
}
.mx-30 {
  margin: 0px 30px;
}
.mx-31 {
  margin: 0px 31px;
}
.mx-32 {
  margin: 0px 32px;
}
.mx-33 {
  margin: 0px 33px;
}
.mx-34 {
  margin: 0px 34px;
}
.mx-35 {
  margin: 0px 35px;
}
.mx-36 {
  margin: 0px 36px;
}
.mx-37 {
  margin: 0px 37px;
}
.mx-38 {
  margin: 0px 38px;
}
.mx-39 {
  margin: 0px 39px;
}
.mx-40 {
  margin: 0px 40px;
}
.mx-41 {
  margin: 0px 41px;
}
.mx-42 {
  margin: 0px 42px;
}
.mx-43 {
  margin: 0px 43px;
}
.mx-44 {
  margin: 0px 44px;
}
.mx-45 {
  margin: 0px 45px;
}
.mx-46 {
  margin: 0px 46px;
}
.mx-47 {
  margin: 0px 47px;
}
.mx-48 {
  margin: 0px 48px;
}
.mx-49 {
  margin: 0px 49px;
}
.mx-50 {
  margin: 0px 50px;
}

/* Margin Top And Bottom*/
.my-1 {
  margin: 1px 0px;
}
.my-2 {
  margin: 2px 0px;
}
.my-3 {
  margin: 3px 0px;
}
.my-4 {
  margin: 4px 0px;
}
.my-5 {
  margin: 5px 0px;
}
.my-6 {
  margin: 6px 0px;
}
.my-7 {
  margin: 7px 0px;
}
.my-8 {
  margin: 8px 0px;
}
.my-9 {
  margin: 9px 0px;
}
.my-10 {
  margin: 10px 0px;
}
.my-11 {
  margin: 11px 0px;
}
.my-12 {
  margin: 12px 0px;
}
.my-13 {
  margin: 13px 0px;
}
.my-14 {
  margin: 14px 0px;
}
.my-15 {
  margin: 15px 0px;
}
.my-16 {
  margin: 16px 0px;
}
.my-17 {
  margin: 17px 0px;
}
.my-18 {
  margin: 18px 0px;
}
.my-19 {
  margin: 19px 0px;
}
.my-20 {
  margin: 20px 0px;
}
.my-21 {
  margin: 21px 0px;
}
.my-22 {
  margin: 22px 0px;
}
.my-23 {
  margin: 23px 0px;
}
.my-24 {
  margin: 24px 0px;
}
.my-25 {
  margin: 25px 0px;
}
.my-26 {
  margin: 26px 0px;
}
.my-27 {
  margin: 27px 0px;
}
.my-28 {
  margin: 28px 0px;
}
.my-29 {
  margin: 29px 0px;
}
.my-30 {
  margin: 30px 0px;
}
.my-31 {
  margin: 31px 0px;
}
.my-32 {
  margin: 32px 0px;
}
.my-33 {
  margin: 33px 0px;
}
.my-34 {
  margin: 34px 0px;
}
.my-35 {
  margin: 35px 0px;
}
.my-36 {
  margin: 36px 0px;
}
.my-37 {
  margin: 37px 0px;
}
.my-38 {
  margin: 38px 0px;
}
.my-39 {
  margin: 39px 0px;
}
.my-40 {
  margin: 40px 0px;
}
.my-41 {
  margin: 41px 0px;
}
.my-42 {
  margin: 42px 0px;
}
.my-43 {
  margin: 43px 0px;
}
.my-44 {
  margin: 44px 0px;
}
.my-45 {
  margin: 45px 0px;
}
.my-46 {
  margin: 46px 0px;
}
.my-47 {
  margin: 47px 0px;
}
.my-48 {
  margin: 48px 0px;
}
.my-49 {
  margin: 49px 0px;
}
.my-50 {
  margin: 50px 0px;
}

/* Margin Top*/
.mt-1 {
  margin-top: 1px;
}
.mt-2 {
  margin-top: 2px;
}
.mt-3 {
  margin-top: 3px;
}
.mt-4 {
  margin-top: 4px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-6 {
  margin-top: 6px;
}
.mt-7 {
  margin-top: 7px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-9 {
  margin-top: 9px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-11 {
  margin-top: 11px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-13 {
  margin-top: 13px;
}
.mt-14 {
  margin-top: 14px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-17 {
  margin-top: 17px;
}
.mt-18 {
  margin-top: 18px;
}
.mt-19 {
  margin-top: 19px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-21 {
  margin-top: 21px;
}
.mt-22 {
  margin-top: 22px;
}
.mt-23 {
  margin-top: 23px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-26 {
  margin-top: 26px;
}
.mt-27 {
  margin-top: 27px;
}
.mt-28 {
  margin-top: 28px;
}
.mt-29 {
  margin-top: 29px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-31 {
  margin-top: 31px;
}
.mt-32 {
  margin-top: 32px;
}
.mt-33 {
  margin-top: 33px;
}
.mt-34 {
  margin-top: 34px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-36 {
  margin-top: 36px;
}
.mt-37 {
  margin-top: 37px;
}
.mt-38 {
  margin-top: 38px;
}
.mt-39 {
  margin-top: 39px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-41 {
  margin-top: 41px;
}
.mt-42 {
  margin-top: 42px;
}
.mt-43 {
  margin-top: 43px;
}
.mt-44 {
  margin-top: 44px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-46 {
  margin-top: 46px;
}
.mt-47 {
  margin-top: 47px;
}
.mt-48 {
  margin-top: 48px;
}
.mt-49 {
  margin-top: 49px;
}
.mt-50 {
  margin-top: 50px;
}

/* Margin Bottom*/
.mb-1 {
  margin-bottom: 1px;
}
.mb-2 {
  margin-bottom: 2px;
}
.mb-3 {
  margin-bottom: 3px;
}
.mb-4 {
  margin-bottom: 4px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-6 {
  margin-bottom: 6px;
}
.mb-7 {
  margin-bottom: 7px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-9 {
  margin-bottom: 9px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-11 {
  margin-bottom: 11px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-13 {
  margin-bottom: 13px;
}
.mb-14 {
  margin-bottom: 14px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-17 {
  margin-bottom: 17px;
}
.mb-18 {
  margin-bottom: 18px;
}
.mb-19 {
  margin-bottom: 19px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-21 {
  margin-bottom: 21px;
}
.mb-22 {
  margin-bottom: 22px;
}
.mb-23 {
  margin-bottom: 23px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-26 {
  margin-bottom: 26px;
}
.mb-27 {
  margin-bottom: 27px;
}
.mb-28 {
  margin-bottom: 28px;
}
.mb-29 {
  margin-bottom: 29px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-31 {
  margin-bottom: 31px;
}
.mb-32 {
  margin-bottom: 32px;
}
.mb-33 {
  margin-bottom: 33px;
}
.mb-34 {
  margin-bottom: 34px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-36 {
  margin-bottom: 36px;
}
.mb-37 {
  margin-bottom: 37px;
}
.mb-38 {
  margin-bottom: 38px;
}
.mb-39 {
  margin-bottom: 39px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-41 {
  margin-bottom: 41px;
}
.mb-42 {
  margin-bottom: 42px;
}
.mb-43 {
  margin-bottom: 43px;
}
.mb-44 {
  margin-bottom: 44px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-46 {
  margin-bottom: 46px;
}
.mb-47 {
  margin-bottom: 47px;
}
.mb-48 {
  margin-bottom: 48px;
}
.mb-49 {
  margin-bottom: 49px;
}
.mb-50 {
  margin-bottom: 50px;
}

/* Margin Left*/
.ml-1 {
  margin-left: 1px;
}
.ml-2 {
  margin-left: 2px;
}
.ml-3 {
  margin-left: 3px;
}
.ml-4 {
  margin-left: 4px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-6 {
  margin-left: 6px;
}
.ml-7 {
  margin-left: 7px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-9 {
  margin-left: 9px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-11 {
  margin-left: 11px;
}
.ml-12 {
  margin-left: 12px;
}
.ml-13 {
  margin-left: 13px;
}
.ml-14 {
  margin-left: 14px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-16 {
  margin-left: 16px;
}
.ml-17 {
  margin-left: 17px;
}
.ml-18 {
  margin-left: 18px;
}
.ml-19 {
  margin-left: 19px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-21 {
  margin-left: 21px;
}
.ml-22 {
  margin-left: 22px;
}
.ml-23 {
  margin-left: 23px;
}
.ml-24 {
  margin-left: 24px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-26 {
  margin-left: 26px;
}
.ml-27 {
  margin-left: 27px;
}
.ml-28 {
  margin-left: 28px;
}
.ml-29 {
  margin-left: 29px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-31 {
  margin-left: 31px;
}
.ml-32 {
  margin-left: 32px;
}
.ml-33 {
  margin-left: 33px;
}
.ml-34 {
  margin-left: 34px;
}
.ml-35 {
  margin-left: 35px;
}
.ml-36 {
  margin-left: 36px;
}
.ml-37 {
  margin-left: 37px;
}
.ml-38 {
  margin-left: 38px;
}
.ml-39 {
  margin-left: 39px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-41 {
  margin-left: 41px;
}
.ml-42 {
  margin-left: 42px;
}
.ml-43 {
  margin-left: 43px;
}
.ml-44 {
  margin-left: 44px;
}
.ml-45 {
  margin-left: 45px;
}
.ml-46 {
  margin-left: 46px;
}
.ml-47 {
  margin-left: 47px;
}
.ml-48 {
  margin-left: 48px;
}
.ml-49 {
  margin-left: 49px;
}
.ml-50 {
  margin-left: 50px;
}

/* Margin Right*/
.mr-1 {
  margin-right: 1px;
}
.mr-2 {
  margin-right: 2px;
}
.mr-3 {
  margin-right: 3px;
}
.mr-4 {
  margin-right: 4px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-6 {
  margin-right: 6px;
}
.mr-7 {
  margin-right: 7px;
}
.mr-8 {
  margin-right: 8px;
}
.mr-9 {
  margin-right: 9px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-11 {
  margin-right: 11px;
}
.mr-12 {
  margin-right: 12px;
}
.mr-13 {
  margin-right: 13px;
}
.mr-14 {
  margin-right: 14px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-16 {
  margin-right: 16px;
}
.mr-17 {
  margin-right: 17px;
}
.mr-18 {
  margin-right: 18px;
}
.mr-19 {
  margin-right: 19px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-21 {
  margin-right: 21px;
}
.mr-22 {
  margin-right: 22px;
}
.mr-23 {
  margin-right: 23px;
}
.mr-24 {
  margin-right: 24px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-26 {
  margin-right: 26px;
}
.mr-27 {
  margin-right: 27px;
}
.mr-28 {
  margin-right: 28px;
}
.mr-29 {
  margin-right: 29px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-31 {
  margin-right: 31px;
}
.mr-32 {
  margin-right: 32px;
}
.mr-33 {
  margin-right: 33px;
}
.mr-34 {
  margin-right: 34px;
}
.mr-35 {
  margin-right: 35px;
}
.mr-36 {
  margin-right: 36px;
}
.mr-37 {
  margin-right: 37px;
}
.mr-38 {
  margin-right: 38px;
}
.mr-39 {
  margin-right: 39px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-41 {
  margin-right: 41px;
}
.mr-42 {
  margin-right: 42px;
}
.mr-43 {
  margin-right: 43px;
}
.mr-44 {
  margin-right: 44px;
}
.mr-45 {
  margin-right: 45px;
}
.mr-46 {
  margin-right: 46px;
}
.mr-47 {
  margin-right: 47px;
}
.mr-48 {
  margin-right: 48px;
}
.mr-49 {
  margin-right: 49px;
}
.mr-50 {
  margin-right: 50px;
}
