html,
body {
  font-family: "Pretendard";
  margin: 0;
  padding: 0;
  min-height: calc(var(--vh, 1vh) * 100);
  text-size-adjust: none;
}

:root {
  --vh: 100%;
}

@font-face {
  font-family: "IM_Hyemin-Bold";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2106@1.1/IM_Hyemin-Bold.woff2")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BMJUA";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/BMJUA.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SUIT-Heavy";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Heavy.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Black";
  src: url("./assets/fonts/roboto/Roboto-Black.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("./assets/fonts/roboto/Roboto-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NotoSansKR-Medium";
  src: url("./assets/fonts/Noto_Sans_KR/NotoSansKR-Medium.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NotoSansKR-Regular";
  src: url("./assets/fonts/Noto_Sans_KR/NotoSansKR-Regular.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NotoSans-Medium";
  src: url("./assets/fonts/Noto_Sans/NotoSans-Medium.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "KyoboHand";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@1.0/KyoboHand.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "RixXladywatermelonR";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/2408-4@1.0/RixXladywatermelonR.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "JGaegujaengyi-Medium-KO";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2110@1.0/JGaegujaengyi-Medium-KO.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MaplestoryOTFBold";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/MaplestoryOTFBold.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
/* @font-face {
  font-family: "NotoSans";
  src: url("https://fonts.googleapis.com/css?family=Noto+Sans");
} */

@import url(https://fonts.googleapis.com/css?family=Noto+Sans);

.notosans * {
  font-family: "Noto Sans", sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #2f3542;
  border-radius: 5px;
}
::-webkit-scrollbar-track {
  background-color: #80808050;
  /* border-radius: 10px; */
  /* box-shadow: inset 0px 0px 5px white; */
}
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.MuiDataGrid-cell--withRenderer {
  display: flex;
  justify-content: center;
}
.MuiDataGrid-cell--withRenderer:focus {
  outline: none !important;
}

fieldset {
  border: none !important;
  outline: none !important;
}
.poll-canvas::-webkit-scrollbar {
  display: none; /* 크롬, 사파리, 오페라, 엣지 */
}

img {
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
  backface-visibility: hidden;
}

.plan-slide-box .swiper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.plan-slide-box .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.plan-slide-box .swiper-button-prev,
.plan-slide-box .swiper-button-next {
  width: 40px;
  height: 40px;
}

.plan-slide-box .swiper-button-prev:after,
.plan-slide-box .swiper-button-next:after {
  width: 40px;
  height: 40px;
  background-color: #2f3542;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.MuiTablePagination-displayedRows {
  font-weight: 600 !important;
  color: #2f3542;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
