.content {
  /* border: 5px solid pink; */
  width: 60%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80%;
}

.mobileContent {
  /* border: 5px solid pink;  */
  /* background-color: red; */
  width: 60%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* margin: 0 auto; */
  height: 80%;
  /* @media (max-width: 1700px) {
    top: 40%;
  } */
}

.boxStyle {
  width: 60px;
  height: 5px;
  margin: 0 auto;
  background-color: #60a7ff;
  border-radius: 15px;
}

.justifyContent {
  display: flex;
  justify-content: center;
}

.middleBox {
  display: flex;
  justify-content: center;
  /* border: 5px solid gray; */
  align-items: center;
}

.middleBoxH2 {
  margin: 0;
  padding: 0;
}

.ArrowSpinningBox {
  position: relative;
  display: flex;
  justify-content: center;
  /* border: 10px solid gray; */
  width: 300px;
  margin: 0 auto;
  margin-top: 30px;
  height: 380px;
  @media (max-width: 1700px) {
    height: 350px;
  }
}

.mobileArrowSpinningBox {
  position: relative;
  display: flex;
  justify-content: center;
  width: 200px;
  margin: 0 auto;
  margin-top: 30px;
  height: 320px;
}

.arrowImg2 {
  position: absolute;
  width: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.moblieArrowImg2 {
  position: absolute;
  width: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button {
  width: 150px;
  height: 40px;
  margin-top: 30px;
  background-color: white;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 2px solid rgb(222, 219, 219);
}

.mobileBody {
  height: calc(var(--vh, 1vh) * 100);
  padding-top: 90px;
  box-sizing: border-box;
  background-color: #f5f5f5;
}

@media (max-width: 1000px) and (orientation: landscape) {
  .mobileBody {
    height: 100vw;
  }
}

@media (max-height: 650px) {
  .mobileBody {
    height: 700px;
  }
}
