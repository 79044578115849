.routing_button {
  color: #fff;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 10px;
  border-radius: 10px;
}
.routing_button:active {
  transform: scale(0.95) translateY(4px);
}
.routing_button > p {
  font: 700 18px Pretendard;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .routing_button {
    position: static;
    margin-bottom: 20px;
  }
}

@media (min-width: 1000px) {
  .routing_button {
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  .routing_button:hover {
    transform: scale(1.05);
  }
  .routing_button:active {
    transform: scale(0.95) translateY(4px);
  }
  .routing_button > p {
    font: 700 20px Pretendard;
    text-decoration: none;
  }
  .routing_button > p:hover {
    text-decoration: underline;
  }
}
