.payment {
  height: 100vh;
  background-color: #eaeaea;
  padding-top: 10px;
  box-sizing: border-box;
}
.payment p {
  margin: 0;
  padding: 0;
}

.payment .body {
  height: calc(100vh - 20px);
  background-color: #fff;
  margin: auto;
  width: 444px;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px #00000029;
  overflow: auto;
}

.payment .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  opacity: 0.8;
}
.payment .logo img {
  width: 40px;
}
.payment .logo p {
  font: 700 28px Pretendard;
  color: #000;
}

.payment .info {
  margin-top: 20px;
  border: 1px solid #eaeaea;
  padding: 10px;
  border-radius: 10px;
}
.payment .info .title {
  font: 700 20px Pretendard;
  color: #000;
  margin: 0;
  padding: 0;
}
.payment .info div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-left: 10px;
}
.payment .info div p:nth-child(1) {
  font: 600 16px Pretendard;
  color: #777;
}
.payment .info div p:nth-child(2) {
  font: 700 18px Pretendard;
  color: #333;
}

.payment .payment_btn {
  width: 100%;
  background-color: #3182f6;
  font: 700 18px Pretendard;
  border-radius: 10px;
  padding: 10px !important;
}
.payment .payment_btn:hover {
  background-color: #2172e6;
}
.payment .payment_btn:active {
  transform: scale(0.98) translateY(2px);
}

.checkout {
  height: 100vh;
  background-color: #eaeaea;
  padding-top: 10px;
  box-sizing: border-box;
}
.checkout p {
  margin: 0;
  padding: 0;
}

.checkout .body {
  height: calc(100vh - 20px);
  background-color: #fff;
  margin: auto;
  width: 444px;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px #00000029;
  overflow: auto;
  position: relative;
}

.checkout .logo {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
}
.checkout .logo > img {
  width: 40px;
}
.checkout .logo > p {
  font: 700 28px Pretendard;
}

.checkout .success {
  margin-top: 50px;
  text-align: center;
}
.checkout .success > img {
  width: 100px;
}
.checkout .success > p {
  margin-top: 20px;
  font: 700 24px Pretendard;
  color: #3182f6;
  line-height: 1.5;
}

.checkout div:has(> .info) {
  margin-top: 50px;
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.checkout .info {
  display: flex;
  justify-content: space-between;
}
.checkout .info > p:nth-child(1) {
  font: 700 18px Pretendard;
  color: #aaa;
}
.checkout .info > p:nth-child(2) {
  font: 700 18px Pretendard;
  color: #333;
}

.checkout .receipt {
  margin-top: 20px;
  border: 2px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.checkout .receipt:hover {
  background-color: #f1f1f1;
}
.checkout .receipt > p {
  font: 700 18px Pretendard;
  color: #555;
}
.checkout .receipt > svg {
  color: #555;
}

.checkout div:has(> .confirm_btn) {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}
.checkout .confirm_btn {
  width: 100%;
  background-color: #3182f6;
  color: #fff;
  border-radius: 0px;
  font: 600 24px Pretendard;
  padding: 15px;
}
.checkout .confirm_btn:hover {
  background-color: #2172e6;
}

.checkout .fail {
  margin-top: 50px;
  text-align: center;
}
.checkout .fail > img {
  width: 100px;
}
.checkout .fail > p {
  margin-top: 20px;
  font: 700 24px Pretendard;
  color: #c33c3c;
  line-height: 1.5;
}

.checkout div:has(> .fail_btn) {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}
.checkout .fail_btn {
  width: 100%;
  background-color: #c33c3c;
  color: #fff;
  border-radius: 0px;
  font: 600 24px Pretendard;
  padding: 15px;
}
.checkout .fail_btn:hover {
  background-color: #b32c2c;
}
