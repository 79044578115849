.main-img {
  /* background-image: url("../assets/images/Main_img.png"); */
  /* background-image: url("../assets/images/live-poll.png"); */
  /* background-image: url("../assets/images/61e802bf15022fb35fbfcfb8_Top.png"); */
  /* background-image: url("../assets/images/istockphoto-1313488972-612x612.jpg"); */
  background-size: cover;
  margin-left: 20%;
  width: 80%;
  height: 100vh;
  position: relative;
  display: flex;
}

.background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  /* position: fixed; */
  /* left: 0;
  right: 0;
  top: 0;
  bottom: 0; */
  z-index: -1;
  position: relative;
}

.main-img .image-container {
  width: 60%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5%;
}

.main-3-container {
  width: 100%;
  height: 1100px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .main-3 {
  background-image: url("../assets/images/main-gradient.png");
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.gradient-box {
  background: linear-gradient(
    88.78deg,
    #ffffff 28.67%,
    rgba(165, 165, 165, 0.29) 78.77%,
    rgba(128, 128, 128, 0) 99.13%
  );
  width: 50%;
  height: 100vh;
  position: relative;
}

/* .text-title {
  color: #c60f0f;
  font-family: "Roboto-Black";
} */

.text-middle-title {
  color: #191919;
  font-family: "Roboto-Bold";
  font-size: 27px;
}

.text {
  color: #797979;
  font-size: 20px;
  font-family: "Noto Sans";
  font-weight: 500;
}

.button {
  align-items: flex-end;
  background: linear-gradient(97.7deg, #c60f0f 1.23%, #630808 134.48%);
  border-radius: 12px;
  border: none;
  height: 59px;
  justify-content: flex-end;
  min-width: 150px;
  padding: 6.5px 50px;
  color: white;
  font-family: "NotoSansKR-Medium";
  font-size: 20px;
  cursor: pointer;
}
.button:hover {
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
}

.main-box {
  align-items: center;
  background-color: #f5f2f2;
  border-radius: 16px;
  box-shadow: 0px 3px 6px #00000029;
  display: inline-block;
  margin: 1.5%;
  flex-direction: column;
  padding: 2%;
  position: relative;
  width: 30%;
  height: 100%;
  box-sizing: border-box;
}

/* .box-img {
  background-image: url("../assets/images/Main_img.png");
  background-size: cover;
  width: 100%;
  height: 40%;
  margin: auto;
} */

.glass-box-container {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.glass-box {
  position: relative;
  -webkit-backdrop-filter: blur(30px) brightness(115%);
  align-items: flex-start;
  backdrop-filter: blur(30px) brightness(115%);
  background-color: #ffffff1d;
  border: 1px solid;
  border-color: #ffffff91;
  border-radius: 27px;
  min-height: 300px;
  width: 640px;
  height: 570px;
  top: 250px;
}

/** 모바일 css */
.mobile {
  min-height: calc(var(--vh, 1vh) * 100);
  padding-top: 180px;
  box-sizing: border-box;
  display: flex;
  /* align-items: center; */
}

.mobile .mobile-container {
  background-color: #ffeded;
  height: 90%;
  width: 90%;
  margin: auto;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
}

.mobile .mobile-button {
  background: linear-gradient(97.7deg, #c60f0f 1.23%, #630808 134.48%);
  border-radius: 10px;
  border: none;
  height: 50px;
  min-width: 120px;
  padding: 6.5px 20px;
  color: white;
  font-family: "NotoSansKR-Medium";
  font-size: 1rem;
}

.mobile .mobile-image-container {
  width: 100%;
}

.mobile-password-container {
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
  box-sizing: border-box;
}

/* 대시보드 css */

.DashBoard {
  text-align: center;
  width: 100%;
  /* width: 1280px; */
  height: 100%;
  padding: 0 5%;
  /* padding-top: 50px; */
  box-sizing: border-box;
}

.DashBoard .profile-box {
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DashBoard .profile-box .profile {
  width: 70%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 50px;
  box-sizing: border-box;
}

.DashBoard .profile-box .info {
  width: auto;
  height: auto;
  margin-right: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid red;
}

.DashBoard .profile-box .info .text-box {
  width: max-content;
  height: 90%;
  text-align: center;
  margin: auto;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
}

.DashBoard .MyProgram-container {
  width: 100%;
  height: 80%;
  margin-top: 3%;
}

.DashBoard .MyProgram {
  width: 100%;
  height: 80%;
  margin: auto;
  overflow: auto;
  background-color: #e0e0e0;
  border-radius: 4px;
  scrollbar-width: none; /*firefox */
  -ms-overflow-style: none; /* IE and Edge */

  padding: 30px;
  box-sizing: border-box;
}

/* chrome, safari, opera */
.DashBoard .MyProgram::-webkit-scrollbar {
  display: none;
}

.DashBoard .MyProgram .program {
  border: 2px solid #2382b9;
  width: 100%;
  height: 100px;
  margin: auto;
}

.DashBoard .profile-grid {
  padding: 2%;
  padding-bottom: 0%;
  box-sizing: border-box;
  width: 100%;
  height: 75%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
}

.DashBoard .profile-grid .cell1 {
  /* grid-column: 1/4;
  grid-row: 1/-1; */
  padding: 10px;
  display: flex;
  text-align: center;
}

.DashBoard .profile-grid .cell2 {
  padding: 10px;
}

.DashBoard .profile-grid .cell3 {
  /* grid-column: 4/7;
  grid-row: 2/4; */
  padding: 10px;
}

.DashBoard .profile-grid .item {
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  overflow: scroll;
  scrollbar-width: none; /*firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

/* chrome, safari, opera */
.DashBoard .profile-grid .item::-webkit-scrollbar {
  display: none;
}

.DashBoard .profile-grid .item .info-grid {
  display: grid;
  height: 80%;
  width: 90%;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 1fr);
  /* grid-auto-flow: dense; */
  margin: auto;
  /* margin-top: 5%; */
}

.DashBoard .profile-grid .item .info-grid .container {
  padding: 10px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.DashBoard .profile-grid .item .info-grid .container .box {
  width: 100%;
  height: 90%;
  background-color: #f5f5f5;
  border-radius: 30px;
  border: 1px solid #d6d6d6;
  display: flex;
  justify-content: center;
}

.DashBoard .profile-grid .item .recent-container {
  width: 100%;
  height: 85%;
  margin: auto;
  overflow: scroll;
  scrollbar-width: none; /*firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

/* chrome, safari, opera */
.DashBoard .profile-grid .item .recent-container::-webkit-scrollbar {
  display: none;
}

.DashBoard .profile-grid .item .recent-container .recent-program {
  border: 2px solid #2382b9;
  width: 100%;
  height: 100px;
  margin: auto;
}

/* subscribe */
.DashBoard .plan-container {
  display: grid;
  /* border: 2px solid #2382b9; */
  grid-template-rows: 1fr 4fr 5fr;
  width: 100%;
  height: 100%;
  gap: 5%;
}

.DashBoard .plan-container .plan {
  width: 100%;
  height: 100%;
  background-color: white;
  border: 2px solid #b43f3f;
  border-radius: 10px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
}

.DashBoard .plan-container .plan .plan-info {
  width: 45%;
  height: auto;
  /* padding: 20px; */
  /* padding-top: 30px; */
  /* box-sizing: border-box; */
  margin: auto;
  padding-left: 5%;
}
.DashBoard .plan-container .plan .line {
  width: 1px;
  height: 80%;
  background-color: #dcdcdc;
  margin: auto;
}

.DashBoard .plan-container .billing-history-container {
  width: 100%;
  height: 90%;
  padding-top: 20px;
  box-sizing: border-box;
  margin: auto;
}

.DashBoard .plan-container .billing-history {
  width: 100%;
  height: auto;
  background-image: linear-gradient(to bottom, #ebebeb 20%, white 20%);
  border: 1px solid #878787;
  border-radius: 10px;
  margin: auto;
  display: grid;
  grid-template-rows: 1fr 4fr;
}

.DashBoard .plan-container .billing-history .top-menu {
  width: 100%;
  height: 100%;
  background-color: none;
  border-bottom: 1px solid #878787;
  display: grid;
  grid-template-columns: 2fr 1.5fr 3fr 1.5fr;
  align-items: center;
}

.DashBoard .plan-container .billing-history .top-menu .menu {
  text-align: left;
  padding: 20px;
  box-sizing: border-box;
}

.DashBoard .plan-container .billing-history .billing-container {
  width: 100%;
  height: 90%;
  overflow: auto;
  scrollbar-width: none; /*firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

/* chrome, safari, opera */
.DashBoard .plan-container .billing-history .billing-container::-webkit-scrollbar {
  display: none;
}

.DashBoard .plan-container .billing-history .billing-container .billing {
  width: 100%;
  height: 20%;
  border-bottom: 1px solid #dcdcdc;
  display: grid;
  grid-template-columns: 2fr 1.5fr 3fr 1.5fr;
  grid-auto-flow: dense;
  align-items: center;
}

.DashBoard .plan-container .billing-history .billing-container .billing .list {
  text-align: left;
  padding: 20px;
  box-sizing: border-box;
}

/* NewDashboard 페이지 css */
/* 0703 지원 개발 */
.NewDashBoard {
  text-align: center;
  width: 100%;
  height: auto;
  padding: 0% 5%;
  box-sizing: border-box;
  margin-bottom: 100px;
  aspect-ratio: 1920 / 1080; /* 가로 세로 비율 설정 */
  border: 0.1px solid #00000000;
}

.NewDashBoard .profile-box {
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.NewDashBoard .profile-box .profile {
  width: 70%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 50px;
  box-sizing: border-box;
}

.NewDashBoard .profile-box .info {
  width: auto;
  height: auto;
  margin-right: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NewDashBoard .profile-box .info .text-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 26px;
}

.NewDashBoard .profile-box .info .text-box div {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}

.NewDashBoard .MyProgram-container {
  width: 100%;
  height: auto;
  margin-top: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.NewDashBoard .Programs-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.NewDashBoard .Box-name {
  width: 11%;
  background-color: #c33c3c;
  border: none;
  border-radius: 8px 8px 0 0;
  font-weight: bold;
  font-size: clamp(8px, 1.14vw, 22px);
  word-break: keep-all;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: clamp(13.3px, 1.041vw, 20px);
}

.NewDashBoard .Contents-box {
  width: 100%;
  background-color: #eeeeee;
  box-shadow: inset 0px 0px 10px #00000033;
  border-radius: 0px 8px 8px 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 50px;
  box-sizing: border-box;
  gap: clamp(95.3px, 7.441vw, 143px);
}

.NewDashBoard .Games-box {
  width: 100%;
  height: 12.5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.NewDashBoard .Games-box .Box-name {
  width: 11%;
  height: 45%;
  background-color: #c33c3c;
  border: none;
  border-radius: 8px 8px 0 0;
  font-weight: bold;
  font-size: clamp(8px, 1.14vw, 22px);
  word-break: keep-all;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NewDashBoard .Games-box .Contents-box {
  width: 100%;
  height: 80%;
  background-color: #eeeeee;
  box-shadow: inset 0px 0px 10px #00000033;
  border-radius: 0px 8px 8px 8px;
  display: flex;
  flex-direction: row;
  /* justify-content: start; */
  align-items: center;
  padding: 20px 50px;
  box-sizing: border-box;
  gap: clamp(95.3px, 7.441vw, 143px);
}

.Games-box .Game-name {
  font-weight: bold;
  font-size: clamp(14.66px, 1.1448vw, 22px);
  cursor: pointer;
  transition: transform 0.5s ease-in-out;
}
.Games-box .Game-name:hover {
  transform: scale(1.1);
}
/* 지원 개발 끝 */

/* plan 페이지 css */
.plan-background {
  background-color: white;
  width: 60%;
  height: 70%;
  box-shadow: 0px 3px 6px #00000029;
  border: none;
  border-radius: 10px;
  position: relative;
}

.plan-background .plan-container {
  width: 90%;
  height: 70%;
  margin: auto;
  border-radius: 10px;
  border: 2px solid #b43f3f;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.plan-background .plan-container .content {
  width: 90%;
  height: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kakao-button {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  padding: 0;
  /* border-radius: 0.25rem; */
  border: none;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.kakao-button:active {
  transform: translateY(5px);
  box-shadow: none;
}

/** mobile plan 페이지 */

.mobile-plan {
  height: calc(var(--vh, 1vh) * 100);
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5%;
  padding-top: 80px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: none;
}

.mobile-plan-background {
  background-color: white;
  width: 100%;
  height: auto;
  box-shadow: 0px 3px 6px #00000029;
  border: none;
  border-radius: 10px;
  position: relative;
  margin: 5%;
  padding: 5%;
  box-sizing: border-box;
}

/** MobileAccessRestricted */

.MobileAccessRestricted {
  height: calc(var(--vh, 1vh) * 100);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5%;
  padding-top: 80px;
  box-sizing: border-box;
  background-color: #f5f5f5;
}

/* .transition_group {
  position: relative;
  padding: 20px 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
} */

/* .fade {
  position: absolute;
  width: 100%;
  height: 100%;
} */

/* .fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: all 0.75s ease-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: all 0.75s ease-out;
} */
