img {
  width: 100%;
}

.paper {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000028;
  box-sizing: border-box;
}

.poll-edit {
  min-height: 500px;
  padding: 30px 0;
  min-width: 1200px;
}

.poll-slide {
  position: relative;
  display: flex;
  min-height: 80vh;
  min-width: 1200px;
  padding: 30px 0;
}

.poll-title {
  border-bottom: 1px solid #00000028;
  margin: 0px 20px 10px 20px;
  padding-bottom: 10px;
}

.poll-canvas {
  position: relative;
  box-sizing: border-box;
  height: calc(80vh - 100px);
  padding-bottom: 7.5%;
}
.poll-canvas::-webkit-scrollbar {
  display: none; /* 크롬, 사파리, 오페라, 엣지 */
}
.poll-count {
  position: absolute;
  right: 0px;
  bottom: 5%;
}

.btn-next {
  position: absolute;
  right: 0px;
  bottom: 50%;
  z-index: 2;
}
.btn-prev {
  position: absolute;
  left: 0px;
  bottom: 50%;
  z-index: 2;
}
.tab {
  position: absolute;
  left: 50%;
  bottom: 5%;
  display: flex;
}
.br-10px {
  border-radius: 10px;
}

.flex {
  display: flex;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

.question {
  width: 65%;
  border-right: 1px solid #00000050;
  padding: 0 20px;
  box-sizing: border-box;
}

.custom {
  width: 35%;
  padding: 20px;
  box-sizing: border-box;
  margin: auto;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.overflow-hidden {
  overflow: hidden;
}

.align-items-center {
  align-items: center;
}

.layout-button {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #ababab;
  cursor: pointer;
}
.layout-button:hover {
  background-color: #efefef;
  border: 1px solid #b43f3f;
}
.layout-button.active {
  background-color: #b43f3f;
}
.layout-button .icon-box {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.layout-button .text-box {
  text-align: center;
  margin: 15px 0;
}

.quiz-edit {
  height: calc(100vh - 200px);
  min-height: 700px;
  overflow: hidden;
}

/* QR코드 부분*/
.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.modal img {
  display: block;
  margin: 0 auto;
  max-width: 50%;
  max-height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10%;
}
