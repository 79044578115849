/* Padding  */
.p-1 {
  padding: 1px;
}
.p-2 {
  padding: 2px;
}
.p-3 {
  padding: 3px;
}
.p-4 {
  padding: 4px;
}
.p-5 {
  padding: 5px;
}
.p-6 {
  padding: 6px;
}
.p-7 {
  padding: 7px;
}
.p-8 {
  padding: 8px;
}
.p-9 {
  padding: 9px;
}
.p-10 {
  padding: 10px;
}
.p-11 {
  padding: 11px;
}
.p-12 {
  padding: 12px;
}
.p-13 {
  padding: 13px;
}
.p-14 {
  padding: 14px;
}
.p-15 {
  padding: 15px;
}
.p-16 {
  padding: 16px;
}
.p-17 {
  padding: 17px;
}
.p-18 {
  padding: 18px;
}
.p-19 {
  padding: 19px;
}
.p-20 {
  padding: 20px;
}
.p-21 {
  padding: 21px;
}
.p-22 {
  padding: 22px;
}
.p-23 {
  padding: 23px;
}
.p-24 {
  padding: 24px;
}
.p-25 {
  padding: 25px;
}
.p-26 {
  padding: 26px;
}
.p-27 {
  padding: 27px;
}
.p-28 {
  padding: 28px;
}
.p-29 {
  padding: 29px;
}
.p-30 {
  padding: 30px;
}
.p-31 {
  padding: 31px;
}
.p-32 {
  padding: 32px;
}
.p-33 {
  padding: 33px;
}
.p-34 {
  padding: 34px;
}
.p-35 {
  padding: 35px;
}
.p-36 {
  padding: 36px;
}
.p-37 {
  padding: 37px;
}
.p-38 {
  padding: 38px;
}
.p-39 {
  padding: 39px;
}
.p-40 {
  padding: 40px;
}
.p-41 {
  padding: 41px;
}
.p-42 {
  padding: 42px;
}
.p-43 {
  padding: 43px;
}
.p-44 {
  padding: 44px;
}
.p-45 {
  padding: 45px;
}
.p-46 {
  padding: 46px;
}
.p-47 {
  padding: 47px;
}
.p-48 {
  padding: 48px;
}
.p-49 {
  padding: 49px;
}
.p-50 {
  padding: 50px;
}

/* Padding Left And Right */
.px-1 {
  padding: 0px 1px;
}
.px-2 {
  padding: 0px 2px;
}
.px-3 {
  padding: 0px 3px;
}
.px-4 {
  padding: 0px 4px;
}
.px-5 {
  padding: 0px 5px;
}
.px-6 {
  padding: 0px 6px;
}
.px-7 {
  padding: 0px 7px;
}
.px-8 {
  padding: 0px 8px;
}
.px-9 {
  padding: 0px 9px;
}
.px-10 {
  padding: 0px 10px;
}
.px-11 {
  padding: 0px 11px;
}
.px-12 {
  padding: 0px 12px;
}
.px-13 {
  padding: 0px 13px;
}
.px-14 {
  padding: 0px 14px;
}
.px-15 {
  padding: 0px 15px;
}
.px-16 {
  padding: 0px 16px;
}
.px-17 {
  padding: 0px 17px;
}
.px-18 {
  padding: 0px 18px;
}
.px-19 {
  padding: 0px 19px;
}
.px-20 {
  padding: 0px 20px;
}
.px-21 {
  padding: 0px 21px;
}
.px-22 {
  padding: 0px 22px;
}
.px-23 {
  padding: 0px 23px;
}
.px-24 {
  padding: 0px 24px;
}
.px-25 {
  padding: 0px 25px;
}
.px-26 {
  padding: 0px 26px;
}
.px-27 {
  padding: 0px 27px;
}
.px-28 {
  padding: 0px 28px;
}
.px-29 {
  padding: 0px 29px;
}
.px-30 {
  padding: 0px 30px;
}
.px-31 {
  padding: 0px 31px;
}
.px-32 {
  padding: 0px 32px;
}
.px-33 {
  padding: 0px 33px;
}
.px-34 {
  padding: 0px 34px;
}
.px-35 {
  padding: 0px 35px;
}
.px-36 {
  padding: 0px 36px;
}
.px-37 {
  padding: 0px 37px;
}
.px-38 {
  padding: 0px 38px;
}
.px-39 {
  padding: 0px 39px;
}
.px-40 {
  padding: 0px 40px;
}
.px-41 {
  padding: 0px 41px;
}
.px-42 {
  padding: 0px 42px;
}
.px-43 {
  padding: 0px 43px;
}
.px-44 {
  padding: 0px 44px;
}
.px-45 {
  padding: 0px 45px;
}
.px-46 {
  padding: 0px 46px;
}
.px-47 {
  padding: 0px 47px;
}
.px-48 {
  padding: 0px 48px;
}
.px-49 {
  padding: 0px 49px;
}
.px-50 {
  padding: 0px 50px;
}

/* Padding Top And Bottom */
.py-1 {
  padding: 1px 0px;
}
.py-2 {
  padding: 2px 0px;
}
.py-3 {
  padding: 3px 0px;
}
.py-4 {
  padding: 4px 0px;
}
.py-5 {
  padding: 5px 0px;
}
.py-6 {
  padding: 6px 0px;
}
.py-7 {
  padding: 7px 0px;
}
.py-8 {
  padding: 8px 0px;
}
.py-9 {
  padding: 9px 0px;
}
.py-10 {
  padding: 10px 0px;
}
.py-11 {
  padding: 11px 0px;
}
.py-12 {
  padding: 12px 0px;
}
.py-13 {
  padding: 13px 0px;
}
.py-14 {
  padding: 14px 0px;
}
.py-15 {
  padding: 15px 0px;
}
.py-16 {
  padding: 16px 0px;
}
.py-17 {
  padding: 17px 0px;
}
.py-18 {
  padding: 18px 0px;
}
.py-19 {
  padding: 19px 0px;
}
.py-20 {
  padding: 20px 0px;
}
.py-21 {
  padding: 21px 0px;
}
.py-22 {
  padding: 22px 0px;
}
.py-23 {
  padding: 23px 0px;
}
.py-24 {
  padding: 24px 0px;
}
.py-25 {
  padding: 25px 0px;
}
.py-26 {
  padding: 26px 0px;
}
.py-27 {
  padding: 27px 0px;
}
.py-28 {
  padding: 28px 0px;
}
.py-29 {
  padding: 29px 0px;
}
.py-30 {
  padding: 30px 0px;
}
.py-31 {
  padding: 31px 0px;
}
.py-32 {
  padding: 32px 0px;
}
.py-33 {
  padding: 33px 0px;
}
.py-34 {
  padding: 34px 0px;
}
.py-35 {
  padding: 35px 0px;
}
.py-36 {
  padding: 36px 0px;
}
.py-37 {
  padding: 37px 0px;
}
.py-38 {
  padding: 38px 0px;
}
.py-39 {
  padding: 39px 0px;
}
.py-40 {
  padding: 40px 0px;
}
.py-41 {
  padding: 41px 0px;
}
.py-42 {
  padding: 42px 0px;
}
.py-43 {
  padding: 43px 0px;
}
.py-44 {
  padding: 44px 0px;
}
.py-45 {
  padding: 45px 0px;
}
.py-46 {
  padding: 46px 0px;
}
.py-47 {
  padding: 47px 0px;
}
.py-48 {
  padding: 48px 0px;
}
.py-49 {
  padding: 49px 0px;
}
.py-50 {
  padding: 50px 0px;
}

/* Padding Top */
.pt-1 {
  padding-top: 1px;
}
.pt-2 {
  padding-top: 2px;
}
.pt-3 {
  padding-top: 3px;
}
.pt-4 {
  padding-top: 4px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-6 {
  padding-top: 6px;
}
.pt-7 {
  padding-top: 7px;
}
.pt-8 {
  padding-top: 8px;
}
.pt-9 {
  padding-top: 9px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-11 {
  padding-top: 11px;
}
.pt-12 {
  padding-top: 12px;
}
.pt-13 {
  padding-top: 13px;
}
.pt-14 {
  padding-top: 14px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-16 {
  padding-top: 16px;
}
.pt-17 {
  padding-top: 17px;
}
.pt-18 {
  padding-top: 18px;
}
.pt-19 {
  padding-top: 19px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-21 {
  padding-top: 21px;
}
.pt-22 {
  padding-top: 22px;
}
.pt-23 {
  padding-top: 23px;
}
.pt-24 {
  padding-top: 24px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-26 {
  padding-top: 26px;
}
.pt-27 {
  padding-top: 27px;
}
.pt-28 {
  padding-top: 28px;
}
.pt-29 {
  padding-top: 29px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-31 {
  padding-top: 31px;
}
.pt-32 {
  padding-top: 32px;
}
.pt-33 {
  padding-top: 33px;
}
.pt-34 {
  padding-top: 34px;
}
.pt-35 {
  padding-top: 35px;
}
.pt-36 {
  padding-top: 36px;
}
.pt-37 {
  padding-top: 37px;
}
.pt-38 {
  padding-top: 38px;
}
.pt-39 {
  padding-top: 39px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-41 {
  padding-top: 41px;
}
.pt-42 {
  padding-top: 42px;
}
.pt-43 {
  padding-top: 43px;
}
.pt-44 {
  padding-top: 44px;
}
.pt-45 {
  padding-top: 45px;
}
.pt-46 {
  padding-top: 46px;
}
.pt-47 {
  padding-top: 47px;
}
.pt-48 {
  padding-top: 48px;
}
.pt-49 {
  padding-top: 49px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-80 {
  padding-top: 80px;
}

/* Padding Bottom */
.pb-1 {
  padding-bottom: 1px;
}
.pb-2 {
  padding-bottom: 2px;
}
.pb-3 {
  padding-bottom: 3px;
}
.pb-4 {
  padding-bottom: 4px;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-6 {
  padding-bottom: 6px;
}
.pb-7 {
  padding-bottom: 7px;
}
.pb-8 {
  padding-bottom: 8px;
}
.pb-9 {
  padding-bottom: 9px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-11 {
  padding-bottom: 11px;
}
.pb-12 {
  padding-bottom: 12px;
}
.pb-13 {
  padding-bottom: 13px;
}
.pb-14 {
  padding-bottom: 14px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pb-17 {
  padding-bottom: 17px;
}
.pb-18 {
  padding-bottom: 18px;
}
.pb-19 {
  padding-bottom: 19px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-21 {
  padding-bottom: 21px;
}
.pb-22 {
  padding-bottom: 22px;
}
.pb-23 {
  padding-bottom: 23px;
}
.pb-24 {
  padding-bottom: 24px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-26 {
  padding-bottom: 26px;
}
.pb-27 {
  padding-bottom: 27px;
}
.pb-28 {
  padding-bottom: 28px;
}
.pb-29 {
  padding-bottom: 29px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-31 {
  padding-bottom: 31px;
}
.pb-32 {
  padding-bottom: 32px;
}
.pb-33 {
  padding-bottom: 33px;
}
.pb-34 {
  padding-bottom: 34px;
}
.pb-35 {
  padding-bottom: 35px;
}
.pb-36 {
  padding-bottom: 36px;
}
.pb-37 {
  padding-bottom: 37px;
}
.pb-38 {
  padding-bottom: 38px;
}
.pb-39 {
  padding-bottom: 39px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-41 {
  padding-bottom: 41px;
}
.pb-42 {
  padding-bottom: 42px;
}
.pb-43 {
  padding-bottom: 43px;
}
.pb-44 {
  padding-bottom: 44px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pb-46 {
  padding-bottom: 46px;
}
.pb-47 {
  padding-bottom: 47px;
}
.pb-48 {
  padding-bottom: 48px;
}
.pb-49 {
  padding-bottom: 49px;
}
.pb-50 {
  padding-bottom: 50px;
}

/* Padding Left */
.pl-1 {
  padding-left: 1px;
}
.pl-2 {
  padding-left: 2px;
}
.pl-3 {
  padding-left: 3px;
}
.pl-4 {
  padding-left: 4px;
}
.pl-5 {
  padding-left: 5px;
}
.pl-6 {
  padding-left: 6px;
}
.pl-7 {
  padding-left: 7px;
}
.pl-8 {
  padding-left: 8px;
}
.pl-9 {
  padding-left: 9px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-11 {
  padding-left: 11px;
}
.pl-12 {
  padding-left: 12px;
}
.pl-13 {
  padding-left: 13px;
}
.pl-14 {
  padding-left: 14px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-16 {
  padding-left: 16px;
}
.pl-17 {
  padding-left: 17px;
}
.pl-18 {
  padding-left: 18px;
}
.pl-19 {
  padding-left: 19px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-21 {
  padding-left: 21px;
}
.pl-22 {
  padding-left: 22px;
}
.pl-23 {
  padding-left: 23px;
}
.pl-24 {
  padding-left: 24px;
}
.pl-25 {
  padding-left: 25px;
}
.pl-26 {
  padding-left: 26px;
}
.pl-27 {
  padding-left: 27px;
}
.pl-28 {
  padding-left: 28px;
}
.pl-29 {
  padding-left: 29px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-31 {
  padding-left: 31px;
}
.pl-32 {
  padding-left: 32px;
}
.pl-33 {
  padding-left: 33px;
}
.pl-34 {
  padding-left: 34px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-36 {
  padding-left: 36px;
}
.pl-37 {
  padding-left: 37px;
}
.pl-38 {
  padding-left: 38px;
}
.pl-39 {
  padding-left: 39px;
}
.pl-40 {
  padding-left: 40px;
}
.pl-41 {
  padding-left: 41px;
}
.pl-42 {
  padding-left: 42px;
}
.pl-43 {
  padding-left: 43px;
}
.pl-44 {
  padding-left: 44px;
}
.pl-45 {
  padding-left: 45px;
}
.pl-46 {
  padding-left: 46px;
}
.pl-47 {
  padding-left: 47px;
}
.pl-48 {
  padding-left: 48px;
}
.pl-49 {
  padding-left: 49px;
}
.pl-50 {
  padding-left: 50px;
}

/* Padding Right */
.pr-1 {
  padding-right: 1px;
}
.pr-2 {
  padding-right: 2px;
}
.pr-3 {
  padding-right: 3px;
}
.pr-4 {
  padding-right: 4px;
}
.pr-5 {
  padding-right: 5px;
}
.pr-6 {
  padding-right: 6px;
}
.pr-7 {
  padding-right: 7px;
}
.pr-8 {
  padding-right: 8px;
}
.pr-9 {
  padding-right: 9px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-11 {
  padding-right: 11px;
}
.pr-12 {
  padding-right: 12px;
}
.pr-13 {
  padding-right: 13px;
}
.pr-14 {
  padding-right: 14px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-16 {
  padding-right: 16px;
}
.pr-17 {
  padding-right: 17px;
}
.pr-18 {
  padding-right: 18px;
}
.pr-19 {
  padding-right: 19px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-21 {
  padding-right: 21px;
}
.pr-22 {
  padding-right: 22px;
}
.pr-23 {
  padding-right: 23px;
}
.pr-24 {
  padding-right: 24px;
}
.pr-25 {
  padding-right: 25px;
}
.pr-26 {
  padding-right: 26px;
}
.pr-27 {
  padding-right: 27px;
}
.pr-28 {
  padding-right: 28px;
}
.pr-29 {
  padding-right: 29px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-31 {
  padding-right: 31px;
}
.pr-32 {
  padding-right: 32px;
}
.pr-33 {
  padding-right: 33px;
}
.pr-34 {
  padding-right: 34px;
}
.pr-35 {
  padding-right: 35px;
}
.pr-36 {
  padding-right: 36px;
}
.pr-37 {
  padding-right: 37px;
}
.pr-38 {
  padding-right: 38px;
}
.pr-39 {
  padding-right: 39px;
}
.pr-40 {
  padding-right: 40px;
}
.pr-41 {
  padding-right: 41px;
}
.pr-42 {
  padding-right: 42px;
}
.pr-43 {
  padding-right: 43px;
}
.pr-44 {
  padding-right: 44px;
}
.pr-45 {
  padding-right: 45px;
}
.pr-46 {
  padding-right: 46px;
}
.pr-47 {
  padding-right: 47px;
}
.pr-48 {
  padding-right: 48px;
}
.pr-49 {
  padding-right: 49px;
}
.pr-50 {
  padding-right: 50px;
}
