.body {
  position: relative;
  height: 100vh;
  background-color: #fff;
  padding-top: 80px;
  box-sizing: border-box;
  overflow: auto;
}
.body p {
  margin: 0;
  padding: 0;
}

.background {
  background: transparent linear-gradient(180deg, #050721 73%, #771b1b 118%) 0% 0% no-repeat
    padding-box;
  width: 100%;
  height: 669px;
  position: absolute;
  z-index: 0;
  opacity: 0.93;
}

.mobile_background {
  background: transparent linear-gradient(180deg, #050721 73%, #771b1b 118%) 0% 0% no-repeat
    padding-box;
}

.hidden {
  display: none;
}

.background_image {
  position: absolute;
  top: -5%;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.content_area {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.routing_button {
  color: #fff;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 10px;
  border-radius: 10px;
}
.routing_button:active {
  transform: scale(0.95) translateY(4px);
}
.routing_button > p {
  font: 700 18px Pretendard;
  text-decoration: underline;
}

.title_grid {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  overflow-x: hidden;
}
.title_grid > img {
  width: 740px;
  height: 91px;
}
.title_grid .title_box {
  position: relative;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 30px;
  height: 36px;
}
.title_grid .title_box > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.title_grid .title_box > p {
  color: white;
  text-align: center;
  margin: auto;
  font: 600 30px Pretendard;
}
.title_grid .title_box > p > span {
  font-weight: 900;
}
.product_grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
}

.product {
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #0000004d;
  overflow: hidden;
}
.product > .header {
  background-color: #c33c3c;
  padding: 10px 25px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  gap: 10px;
}
.product > .header > p {
  padding: 0;
  margin: 0;
  font: 500 24px Pretendard;
  color: #fff;
}
.product > .product_body {
  /* height: 260px; */
  height: auto;
  padding-top: 35px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.product > .product_body > .product_name {
  font: 700 30px Pretendard;
  color: #343434;
  line-height: 1;
  text-align: center;
}
.product > .product_body > .product_name > span {
  font: 600 16px Pretendard;
  line-height: 1.5;
  color: #797979;
}
.product > .product_body > .product_price {
  width: 100%;
  font: 700 30px Pretendard;
  color: #343434;
  margin-top: 45px;
  margin-bottom: 20px;
  text-align: center;
}
.product > .product_body > .explain {
  background-color: #f1f1f1;
  width: 100%;
  padding: 40px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.product > .product_body > .explain > p {
  display: flex;
  font: 500 16px Pretendard;
  word-break: keep-all;
}
.product > .product_body > .explain > p:before {
  content: "•";
  color: #333;
  margin-right: 10px;
}
.product > .footer {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.product > .footer > p {
  text-align: center;
  color: #808080;
  font: 500 14px Pretendard;
  margin-bottom: 20px;
}
.product > .footer > button {
  font: 700 20px Pretendard;
  padding: 10px 0;
  border-radius: 12px;
  border: 2px solid #c33c3c;
  color: #333;
  background-color: #fff;
}
.product > .footer > button:active {
  transform: scale(0.95) translateY(4px);
}
/* .title_grid .title_box > p.plan_type {
  text-align: center;
  color: #fff;
  font: 800 33px Pretendard;
  text-decoration: underline;
  margin-top: 10px;
} */
span.plan_type {
  text-align: center;
  color: #fff;
  font: 800 33px Pretendard;
  text-decoration: underline;
  margin-top: 10px;
}

/* Paypage */
.paypage {
  background-color: #f5f5f5;
  height: 100vh;
  padding-top: 80px;
  box-sizing: border-box;
  position: relative;
  overflow: auto;
}
.paypage p,
.paypage span {
  margin: 0;
  padding: 0;
}

.paypage > .back_btn {
  display: flex;
  gap: 5px;
  font: 700 30px Pretendard;
  color: #333;
  transition: all 0.3s ease;
  margin: 40px 20px;
  z-index: 1;
}
.paypage > .back_btn:hover {
  transform: scale(1.1);
  background-color: transparent;
}
.paypage > .back_btn > svg {
  font-size: 30px;
  font-weight: 700;
}

.paypage > .container {
  position: absolute;
  width: 100%;
  max-width: 1280px;
  /* height: 100%; */
  /* background-color: #fff; */
  left: 50%;
  top: 80px;
  transform: translateX(-50%);
  padding: 40px 0;
  z-index: 0;
}
.paypage > .container > .title {
  font: 700 30px Pretendard;
  text-align: center;
}
.paypage > .container > .sub_title {
  margin-top: 30px;
  font: 500 16px Pretendard;
  text-align: center;
  color: #c33c3c;
  line-height: 1.2;
}

.paypage > .container > .grid {
  width: 100%;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 8fr 4fr;
}
.paypage > .container .paper {
  height: auto;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.paypage > .container > .grid > div {
  padding: 10px;
}
.paypage > .container > .grid .title {
  font: 600 24px Pretendard;
}
.paypage > .container > .grid .title > span {
  color: #c33c3c;
  font: 500 16px Pretendard;
}
.paypage .grid .paper > .checkbox {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}
.paypage .grid .paper > .checkbox > span {
  font: 500 16px Pretendard;
}
.paypage .grid .paper > .checkbox > .see_more {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.paypage .grid .paper > .checkbox > .see_more > span {
  font: 400 14px Pretendard;
  color: #888;
  cursor: pointer;
  text-decoration: underline;
}

.paypage .divider {
  margin: 10px 0;
  border-color: #ababab;
}
.paypage .product_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.paypage .product_info:last-child {
  margin-bottom: 0px;
}
.paypage .product_info > p > span {
  color: #c33c3c;
}
.paypage .product_info > p:first-child {
  font: 500 18px Pretendard;
  color: #888888;
}
.paypage .product_info > p:nth-child(2) {
  font: bold 18px Pretendard;
  color: #333;
  text-align: right;
}
.paypage .product_info > .select {
  background-color: #f5f5f5;
}
.paypage .payment_btn {
  width: 100%;
  background-color: #3182f6;
  font: 700 20px Pretendard;
}
.paypage .payment_btn:hover {
  background-color: #2172e6;
}
.paypage .payment_btn:active {
  transform: scale(0.95) translateY(4px);
}
.paypage div:has(> .user_info) {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 30px 0;
}
.paypage .user_info {
  display: flex;
  gap: 10px;
  align-items: center;
}
.paypage .user_info > p {
  width: 15%;
  font: 500 20px Pretendard;
}
.paypage .user_info > p > span {
  color: #c33c3c;
  font-size: 16px;
}

@media (max-width: 768px) {
  .routing_button {
    position: static;
    margin-bottom: 20px;
  }
}

@media (min-width: 1000px) {
  .routing_button {
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  .routing_button:hover {
    transform: scale(1.05);
  }
  .routing_button:active {
    transform: scale(0.95) translateY(4px);
  }
  .routing_button > p {
    font: 700 20px Pretendard;
    text-decoration: none;
  }
  .routing_button > p:hover {
    text-decoration: underline;
  }
}

@media (max-width: 1000px) {
  .title_box {
    height: clamp(25px, 8.1vw, 36px) !important;
  }
  .title_box > p {
    margin-top: 5px !important;
    font-size: clamp(11px, 3.7vw, 18px) !important;
  }
}
