/* .Layout { */
/* min-width: 1080px; */
/* } */

.header-menu {
  margin-right: 24px;
  height: 80px;
  display: flex;
  cursor: pointer;
  align-items: center;
  font-family: "NotoSans-Medium";
  font-size: 18px;
  color: #707070;
  background-color: transparent;
  border: none;
}
.header-menu-onclick {
  margin-right: 24px;
  height: 80px;
  display: flex;
  cursor: pointer;
  align-items: center;
  font-family: "Noto Sans";
  font-weight: bold;
  font-size: 18px;
  color: #b43f3f;
  background-color: transparent;
  border: none;
  border-bottom: 3px solid #b43f3f;
}

.header-menu:hover {
  background-color: #efefef;
}

/* .main {
  padding-top: 80px;
} */

.signup-button {
  background-image: linear-gradient(to right, #ef3a3a 0%, #cf007d 51%, #7706d8 100%);
  background-size: 150% auto;
  transition: 0.5s;
  border-radius: 20px;
  border: none;
  margin-right: 90px;
  width: 140px;
  height: 40px;
  font-family: "NotoSans-Medium";
  font-size: 18px;
  color: white;
}

.signup-button:hover {
  background-position: right center; /* change the direction of the change here */
}
.signup-button.hide {
  display: none;
}
.code-button {
  background-image: linear-gradient(to right, #833ab4 0%, #fd1d1d 50%, #fcb045 100%);
  border-radius: 10px;
  border: none;
  width: 100px;
  height: 40px;
  color: white;
  font-family: "NotoSans-Medium";
  font-size: 16px;
  margin-right: 24px;
  transition: 0.5s;
  cursor: pointer;
  font-weight: bold;
}
.code-button:hover {
  transform: scale(1.1);
  background-image: linear-gradient(to right, #732aa4 0%, #ed0d0d 50%, #eca035 100%);
}

.main {
  /* height: 100%; */
}

/** 모바일 헤더 css */
.mobile-menu {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100 - 80px);
  position: absolute;
  top: 80px;
  left: -130%;
  opacity: 1;
  transition: all 0.5s ease;
  margin: 0px;
}

.mobile-menu-active {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100 - 80px);
  top: 80px;
  opacity: 1;
  position: absolute;
  z-index: 1;
  background-color: white;
  opacity: 1;
  transition: all 0.5s ease;
  left: -30px;
  margin: 0px;
  overflow: auto;
}

li {
  font-family: "NotoSansKR-Medium";
  font-weight: bold;
  font-size: 1.2rem;
  color: #707070;
  padding: 0 2rem;
  list-style: none;
  margin: 10px;
}

ul.contents {
  font-family: "NotoSansKR-Medium";
  font-weight: bold;
  font-size: 1.2rem;
  color: #707070;
  padding: 0 2rem;
  list-style: none;
  margin: 10px;
}

.contents li {
  font-family: "NotoSansKR-Medium";
  font-size: 1rem;
  color: #909090;
  list-style: none;
  margin: 10px;
  padding: 10px 5px;
  line-height: 2rem;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: 5px 5px 7px #00000020;
  border-radius: 8px;
  cursor: pointer;
}

/* -- 대시보드 레이아웃 css -- */

.DashBoardLayout {
  background-color: #f5f5f5;
  width: 100%;
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 80px;
  box-sizing: border-box;
}

.DashBoardLayout .side-bar {
  background-color: white;
  width: 15%;
  box-shadow: 0px 0 7px #00000050;
  float: left;
  height: 100%;
  position: fixed;
  /* min-height: 100%; */
}

.DashBoardLayout .side-bar .side-button-container {
  display: grid;
  margin-top: 10%;
  height: 400px;
  /* grid-template-row: 1fr 1fr 1fr; */
  text-align: left;
  width: 70%;
  float: right;
  margin-right: 10%;
  align-items: center;
}

.DashBoardLayout .side-bar .side-button-container .side-button {
  display: flex;
  font-size: 1rem;
  height: 80px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #707070;
  cursor: pointer;
}

.DashBoardLayout .side-bar .side-button-container .side-button-on {
  display: flex;
  font-size: 1rem;
  height: 80px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: #c60f0f;
  color: white;
  cursor: pointer;
}

.DashBoardLayout .side-bar .side-button-container .side-button:hover {
  animation: onHover 1s 0s linear;
  background-color: #c60f0f;
  color: white;
}

@keyframes onHover {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.DashBoardLayout .main-content {
  float: left;
  padding-left: 15%;
  padding-top: 30px;
  padding-bottom: 30px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

/* footer */

.Footer {
  width: 100%;
  min-width: 320px;
  /* bottom: 0%; */
  min-height: 200px;
  height: auto;
  overflow: visible;
}

.Footer .container {
  background-color: rgb(26, 31, 39);
  color: rgb(131, 131, 131);
  display: block;
  padding: 30px 0 30px;
  height: 100%;
}

.Footer .btn {
  background: none;
  border: none;
  color: inherit;
  font-size: 1rem;
  font-family: inherit;
  cursor: pointer;
}

.Footer .btn:hover {
  color: white;
}

/** footer - mobile */
.MobileFooter {
  width: 100%;
  bottom: 0%;
  /* height: 100%; */
}

.MobileFooter .footer-container {
  background-color: rgb(26, 31, 39);
  color: rgb(131, 131, 131);
  display: block;
  padding: 20px 10px 20px 10px;
  box-sizing: border-box;
}

.MobileFooter .btn {
  background: none;
  border: none;
  color: inherit;
  font-size: 1rem;
  font-family: inherit;
}
