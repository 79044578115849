.Container {
  display: flex;
  flex-direction: column;
  height: auto;

  margin: 0 16px;

  font-family: "Pretendard";
}

.Page {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.Title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Title p {
  padding: 0;
  margin: 0;

  font-size: 32px;
  font-weight: bold;
}

.PageContents {
  margin-top: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.FirstPageImg {
  width: 100%;
  height: 300px;
}

.FirstPageImg img {
  width: 100%;
  height: 100%;
}

.Summary {
  margin: 0;
  margin-top: 18px;
  align-self: flex-end;

  font-size: 16px;
}

.Pdf {
  margin: 0;
  margin-top: 4px;
  align-self: flex-end;

  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
  color: #081f5c;

  cursor: pointer;
}

.FirstPageUserInfo {
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  gap: 4px;

  align-self: flex-end;
}

.UserInfo {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-self: flex-end;
}

.UserInfo p {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.FirstPageFooter {
  margin-top: 60px;
  padding: 0 20px;
  display: flex;
}

.FirstPageFooter .PrevBtn {
  width: 20%;
  height: 40px;
}

.FirstPageFooter .NextBtn {
  width: 100%;
  padding: 10px 28px;
  background-color: #c33c3c;
  border-radius: 20px;

  color: white;
  font-size: 18px;
  font-weight: 600;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: all 0.3s ease;
}

.NextBtn:hover {
  background-color: #a32e2e;
  transform: "scale(1.03)";
}

.NextBtn:active {
  background-color: #892525;
  transform: scale(0.97);
}

.SecondPageChart {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SecondPageUserType {
  padding: 0;
  margin: 0;
  margin-top: 30px;
  font-size: 16px;

  word-break: keep-all;
}

.PageFooter {
  margin-top: 40px;
  padding: 0 16px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.PageFooter .PrevBtn {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;

  color: #c33c3c;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.PrevBtn:hover {
  color: #a32e2e;
  transform: "scale(1.03)";
}

.PrevBtn:active {
  color: #892525;
  transform: scale(0.97);
}

.PageFooter .NextBtn {
  padding: 10px 28px;
  background-color: #c33c3c;
  border-radius: 20px;

  color: white;
  font-size: 18px;
  font-weight: 600;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: all 0.3s ease;
}

.ThirdPageTable {
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
}

.ThirdPageTable table {
  width: 100%;
  border-collapse: collapse;
}

.ThirdPageTable th {
  padding: 6px 2px;
  border-left: 1px solid #a6a6a6;
  font-weight: bold;
  font-size: 12px;
  background-color: #081f5c;
  color: white;
}

.ThirdPageTable td {
  padding: 4px 0px;
  border-left: 1px solid #a6a6a6;
  font-weight: bold;
  font-size: 12px;
  border-bottom: 1px solid #a6a6a6;
  text-align: center;
  white-space: nowrap; /* 줄 바꿈 방지 */
}

.ThirdPageTable td:last-child,
.ThirdPageTable th:last-child {
  border-right: 1px solid #a6a6a6;
}

.ThirdPageTable td:first-child,
.ThirdPageTable th:first-child {
  padding: 4px 6px;
}

.UserTypeInfo {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 48px;
  gap: 8px;
}

.UserTypeInfoTitle {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}

.UserTypeInfoWord {
  font-size: 13px;
  font-weight: 400;
  margin: 0;

  word-break: keep-all;
  text-align: center;
}

.UserTypeBox {
  border: 1px solid #a6a6a6;
  border-radius: 14px;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 28px;
  padding: 13px;
}

.KeyWordTitle {
  font-size: 18px;
  font-weight: 700;
  color: #f5c246;

  margin: 0;
}

.KeyWordDesc {
  margin: 0;
  margin-top: 10px;

  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-align: center;

  word-break: keep-all;
}

.UserTypeBoxInfo {
  font-size: 17px;
  font-weight: 400;

  word-break: keep-all;
  line-height: 1.5;
}
.UserTypeBoxInfo span {
  display: flex;
}
.UserTypeBoxInfo span:before {
  content: "•";
  color: #000;
  margin-right: 5px;
}

.alert {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-self: flex-end;
  margin: 0;
  margin-top: 10px;
}

.Box {
  border: 1px solid #a6a6a6;
  border-radius: 10px;

  width: 100%;
}

.FifthPageProsTitle {
  margin: 0;
  padding: 10px 14px;
  border-bottom: 1px solid #a6a6a6;
  border-radius: 10px 10px 0 0;
  background-color: #081f5c;
  color: white;

  font-size: 18px;
  font-weight: 700;
}

.FifthPageProsDesc {
  margin: 0;
  padding: 14px 14px 20px 14px;

  font-size: 16px;
  font-weight: 500;

  word-break: keep-all;
  line-height: 1.5;
}

.FifthPageProsDesc span {
  display: flex;
}

.FifthPageProsDesc span:before {
  content: "•";
  color: #000;
  margin-right: 5px;
}

.SixthPageDesireTitle {
  margin: 0;
  padding: 10px 14px;

  font-size: 18px;
  font-weight: 700;

  border-radius: 10px 10px 0 0;

  color: white;
  background-color: #2f3542;
}

.SixthPageDesireDesc {
  margin: 0;
  padding: 20px 14px;

  font-size: 16px;
  font-weight: 500;

  word-break: keep-all;
  line-height: 1.5;
}

.SixthPageDesireDesc span {
  display: flex;
}
.SixthPageDesireDesc span:before {
  content: "•";
  color: #000;
  margin-right: 5px;
}

.SeventhPageStressTitle {
  margin: 0;
  padding: 10px 14px;

  font-size: 18px;
  font-weight: 700;

  border-radius: 10px 10px 0 0;

  color: white;
  background-color: #2f3542;
}

.SeventhPageStressDesc {
  margin: 0;
  padding: 20px 14px;

  font-size: 16px;
  font-weight: 500;

  word-break: keep-all;
  line-height: 1.5;
}

.SeventhPageStressDesc span {
  display: flex;
}

.SeventhPageStressDesc span:before {
  content: "•";
  color: #000;
  margin-right: 5px;
}

.EighthPageContentsTitle {
  margin: 0;
  padding: 10px 8px;
  padding-bottom: 0;

  font-size: 18px;
  font-weight: 600;
}

.EighthPageContentsDesc {
  margin: 0;

  padding: 8px 16px;
  padding-bottom: 0;

  font-size: 16px;
  font-weight: 400;

  word-break: keep-all;
  line-height: 1.5;
}

.EighthPageContentsDesc span {
  display: flex;
}

.EighthPageContentsDesc span:before {
  content: "•";
  color: #000;
  margin-right: 5px;
}
