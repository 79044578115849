.notice {
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  overflow: hidden;
  border: 3px solid #ddd;
}
.notice p {
  padding: 0;
  margin: 0;
}
.hidden {
  display: none;
}

.notice .header {
  padding: 15px 0;
  background-color: #ffddee;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.notice .header > p {
  font: 700 18px Pretendard;
  text-align: center;
  color: #555;
}
.notice .header > svg {
  font-size: 30px;
  color: #555;
}

.notice .body {
  padding: 15px;
}
.notice .body > p {
  font: 500 16px Pretendard;
  color: #333;
  word-break: keep-all;
  line-height: 1.5;
  margin-bottom: 10px;
}

.notice .body .info {
  margin-top: 10px;
}
.notice .body .info p:nth-child(1) {
  font: 600 18px Pretendard;
  color: #333;
  background-color: #ddddff;
  padding: 5px 10px;
  width: fit-content;
}
.notice .body .info p:nth-child(n + 1) {
  font: 600 16px Pretendard;
  color: #333;
  padding: 5px 10px;
  word-break: keep-all;
}

.notice .footer {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ddd;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.notice .footer > div:first-child {
  display: flex;
  gap: 5px;
  align-items: center;
}
.notice .footer > div:first-child > p {
  font: 500 16px Pretendard;
}
.notice .footer .check_box {
  padding: 0;
}
.notice .footer button {
  font: 500 16px Pretendard;
  color: #333;
  border: 1px solid #ddd;
  background-color: #f5f5f5;
}
