@font-face {
  font-family: "SUIT-ExtraBold";
  src: url("../../../assets/fonts/SUIT/SUIT-ExtraBold.ttf") format("woff");
  /* font-weight: normal;
  font-style: normal; */
}
.h1Font {
  font-family: "SUIT-ExtraBold";
}

.content {
  position: absolute;
  /* border: 5px solid pink; */
  top: 55%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  height: 115%;
}

.justifyContent {
  display: flex;
  justify-content: center;
  /* border: 1px solid black; */
}

.buttonBox {
  display: flex;
  margin: 0px auto;
  justify-content: center;
  border: 2px solid rgb(222, 219, 219);
  background-color: white;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  align-items: center;
  width: 180px;
  height: 40px;
}

.button {
  width: 30px;
  height: 30px;
  /* position: relative; */
  /* left: 50px; */
}

.numberOfOptionsH2 {
  /* position: relative; */
  /* left: 50px; */
  margin: 0;
  padding: 0;
}
.min2Max8 {
  position: relative;
  left: 70px;
  margin: 0;
  padding: 0;
}

.optionInputBox {
  /* position: absolute; */
  /* border: 4px solid pink; */
  /* width: 300px; */
  width: 100%;
  /* margin: 0 auto; */
  min-height: 450px;
  /* max-height: 450px; */
  overflow: auto;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
  flex: 1;
}

.rouletteBox {
  position: absolute;
  display: flex;
  justify-content: center;
  /* border: 1px solid black; */
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(-47deg);
  width: 450px;
  height: 450px;
  z-index: -10;
}

.mobileRouletteBox {
  position: absolute;
  display: flex;
  justify-content: center;
  /* border: 1px solid black; */
  top: 45%;
  left: 53%;
  transform: translateX(-50%) translateY(-50%) rotate(-47deg);
  width: 300px;
  height: 300px;
  z-index: -10;
}

.rouletteButton {
  position: absolute;
  /* transform: translateX(3%) translateY(173%) rotate(47deg); */
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(47deg);
  cursor: pointer;

  width: 100px;
  height: 100px;
  border-radius: 50%;
  z-index: 10;
  font-size: 20px;

  font-weight: bold;
  background-color: white;
  border: 1px solid white;
  line-height: 100px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.rouletteButton:hover {
  background-color: #f5f5f5;
  transform: translateX(-50%) translateY(-50%) rotate(47deg) scale(1.1);
}
.rouletteButton:active {
  background-color: #f5f5f5;
  transform: translateX(-50%) translateY(-50%) rotate(47deg) scale(0.9);
}

.rouletteBackGround {
  position: absolute;
  width: 500px;
  height: 500px;
  /* border: 1px solid pink; */
  background-image: url("./RouletteImg/RouletteImg3.png");
  background-repeat: no-repeat;
  top: -25px;
  left: -25px;
  background-size: cover;
  z-index: 5;
}

.mobileRouletteBackGround {
  position: absolute;
  width: 330px;
  height: 330px;
  background-image: url("./RouletteImg/RouletteImg3.png");
  background-repeat: no-repeat;
  top: -13px;
  left: -13px;
  background-size: cover;
  z-index: 5;
}

.buttonBackGround {
  position: absolute;
  top: 50%;
  left: 50%;
  /* transform: translateX(2%) translateY(149%); */
  transform: translateX(-50%) translateY(-50%);
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 5;
}

.startOrReset {
  width: 100px;
  height: 50px;
  border: 2px solid rgb(222, 219, 219);
  background-color: white;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 10px;
}

/*룰렛 포인터 위치만 조정*/
img[alt~="roulette-static"] {
  content: url("./RouletteImg/RouletteImg1.png");
}

/*룰렛 포인터*/
.roulettePointer {
  position: absolute;
  z-index: 20;
  width: 50px;
  transform: rotate(47.5deg);
  right: 35px;
  top: 15px;
}

.resultBox {
  justify-content: center;
  display: flex;
  width: 300px;
  margin: 0 auto;
  /* border: 1px solid black; */
}

.inputCss {
  text-align: center;
  /* width: 200px; */
  width: 50%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid rgb(222, 219, 219);
  /* flex-shrink: 0; */
  box-sizing: border-box;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.boxStyle {
  width: 50px;
  height: 5px;
  margin: 0 auto -10px auto;
  background-color: #60a7ff;
  border-radius: 15px;
}

.mobAlert {
  font-family: Pretendard;
  font-size: clamp(10px, 3.5vw, 14px);
  /* font-size: 10px; */
  /* font-size: 14px; */
  /* font-size: 3.5vw; */
}

.mobRouletteBox {
  position: relative;
}

@media screen and (max-width: 1000px) {
  .buttonBox {
    margin: 0;
  }
  .mobileRouletteBox {
    position: relative;
    /* top: unset; */
    /* left: unset; */
    transform: translateX(-50%) translateY(-50%) rotate(-47deg);
    /* transform: rotate(-47deg); */
    z-index: 1;
  }
  /* 2번째 child */
  .mobRouletteBox > div:nth-child(2) {
    width: 300px;
    height: 300px;
    transform: rotate(-46deg);
  }

  .rouletteButton {
    width: 75px;
    height: 75px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }

  .buttonBackGround {
    width: 90px;
    height: 90px;
  }

  .content {
    position: unset;
    top: unset;
    left: unset;
    transform: unset;
    height: 100%;
    /* overflow: auto; */
  }

  .roulettePointer {
    width: 30px;
    right: 50%;
    top: 5px;
    transform: rotate(0deg) translateX(50%);
  }

  /* .mobileRouletteBackGround {
    width: 200px;
    height: 200px;
  } */
}
