@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

@property --angle {
  syntax: "<angle>";
  inherits: false;
  initial-value: 0deg;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui,
    Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic",
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
} */

.body {
  background-color: #f5f5f5;
  min-height: 100vh;
  padding-bottom: 30px;
  box-sizing: border-box;
}

a:visited {
  color: none;
}

a:hover {
  color: white;
}

html {
  /* Prevent font scaling in landscape */
  text-size-adjust: none;
  -webkit-text-size-adjust: none; /*Chrome, Safari, newer versions of Opera*/
  -moz-text-size-adjust: none; /*Firefox*/
  -ms-text-size-adjust: none; /*Ie*/
  -o-text-size-adjust: none; /*old versions of Opera*/
  /* font-size: 62.5%; */
}

span.point-se,
span.point-sw,
span.point-ne,
span.point-nw {
  width: 15px !important;
  height: 15px !important;
}

.fullscreen {
  /* height: 100%; */
  flex: 1;
  flex-shrink: 0;
}

.MuiTooltip-tooltip {
  width: max-content;
  font-size: 14px !important;
}

button.kbc-button.disabled {
  opacity: 1;

  font-size: 14px;
  font-weight: 600;
}
