.button {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  font: bold 18px NotoSansKR-Regular;
  border: 2.5px solid #000;
  width: 150px;
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.35);
  cursor: pointer;
  transition: scale 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out;
  margin: 10px;
}
.button:hover {
  border: 2.5px solid #c33c3c;
  background-color: #c33c3c;
  color: #fff;
  scale: 1.1;
}
.button:active {
  box-shadow: none;
  transform: translateY(4px);
}

.text {
  color: #797979aa;
  font-size: 16px;
  line-height: 1.8rem;
  font-family: NotoSansKR-Medium;
  margin: 16px 8px;
  margin-bottom: 10px;
  word-break: keep-all;
  margin: 30px auto;
}

.sub-title {
  /* font-family: "NotoSansKR-Medium"; */
  font-family: "Pretendard";
  font-size: 35px;
  line-height: 1;
  font-weight: bold;
  white-space: nowrap;
  /* padding: 0.8rem 1rem 0.9rem 1rem; */
  color: #353535aa;
  margin: 16px 8px;

  /* background-color: #eee; */
  border-radius: 0.2rem;
}

.card-grid {
  display: flex;
  justify-content: center;
  width: 50%;
  /* padding: 0 20px; */
  /* background-color: yellow; */
}

.card {
  /* background: #fff;
  background-size: cover;
  background-repeat: no-repeat; */
  width: 85%;
  min-width: 440px;
  aspect-ratio: 613/325;
  padding: clamp(10px, 1.2vw, 30px);

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card .title {
  font: bold 28px Pretendard;
  font-size: clamp(20px, 1vw, 28px);
  margin-bottom: clamp(10px, 1vw, 20px);
  -webkit-text-stroke: 0.5px #000;
}
.card-button {
  width: max-content;
  font: 19px Pretendard;
  font-size: clamp(14px, 1vw, 18px);
  border: none;
  background-color: #fff;
  border-radius: 10px;
  padding: clamp(2px, 1vw, 8px) 25px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: scale 0.3s ease-in-out;
  margin-top: clamp(8px, 1vw, 20px);
  color: #000;
}
.card .card-button:hover {
  scale: 1.1;
}
.card .card-button:active {
  box-shadow: none;
  transform: translateY(4px);
}

.simple-game-button {
  width: max-content;
  font: 16px NotoSansKR-Regular;
  border: none;
  background-color: #fff;
  border-radius: 10px;
  padding: 7px 20px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: scale 0.3s ease-in-out;
  margin-top: clamp(8px, 1vw, 20px);
}
.simple-game-button:hover {
  scale: 1.1;
}
.simple-game-button:active {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  transform: translateY(4px);
}

.simple-game-card {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 10px;
  width: 250px;
  height: 300px;
}

.mobile-simple-game-button {
  width: 15%;
  aspect-ratio: 1/1;
  padding: 10px;
  background-color: #ccc;
  border-radius: 10px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-simple-game-button:active {
  box-shadow: none;
  transform: translateY(4px);
}

@media screen and (max-width: 1000px) {
  .sub-title {
    font-size: 28px;
    padding: 0 7.5pt;
    margin: 7.5pt 0;
    background-color: white;
  }

  .card {
    position: relative;
    width: 90%;
    min-width: 0;
    height: 0;
    padding-top: calc(325 / 613 * 90%);
    /* aspect-ratio: 613/315; */
    /* padding: 20px; */
    background-color: #aaa;
    box-sizing: border-box;
    margin-bottom: 20px;
  }

  .card-button {
    position: absolute;
    bottom: 10px;
    font-size: clamp(12px, 1vw, 16px);
    padding: clamp(5px, 1vw, 10px) 20px;
    border-radius: 6px;
    /* margin-bottom: 10px; */
  }

  .card .title {
    font-size: clamp(18px, 1vw, 24px);
  }

  .card .content {
    position: absolute;
    top: 10px;
  }

  .card-content {
    font-family: Pretendard;
    font-size: clamp(9px, 3.1vw, 14px);
    word-break: keep-all;
  }
}
